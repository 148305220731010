import { PolicyModality } from "nirvana-react-elements"
import moment from "moment-timezone"

import {
    AvailablePlanType,
    COVERAGE_CONFIG,
    CoverageBreakdownSection,
} from "./coverage.config"
import {
    AvailableCoverageHistorySortField,
    POLICIES_CONFIG,
} from "./policies.config"
import { GENERAL_CONFIG } from "./general.config"
import { CHECKER_CONFIG } from "./checker.config"
import { TOOLTIPS_CONFIG } from "./tooltips.config"

export enum PolicyColumnKey {
    MEMBER_ID = "MEMBER_ID",
    PATIENT_NAME = "PATIENT_NAME",
    PATIENT_DOB = "PATIENT_DOB",
    PATIENT_TYPE = "PATIENT_TYPE",
    PATIENT_ID = "PATIENT_ID",
    PATIENT_NEXT_APPOINTMENT_DATE = "PATIENT_NEXT_APPOINTMENT_DATE",
    ADDITIONAL_POLICY_PAYER = "ADDITIONAL_POLICY_PAYER",
    ADDITIONAL_POLICY_MEMBER_ID = "ADDITIONAL_POLICY_MEMBER_ID",
    ADDITIONAL_POLICY_TYPE = "ADDITIONAL_POLICY_TYPE",
    ADDITIONAL_POLICY_TYPE_PLAN_TYPE = "ADDITIONAL_POLICY_TYPE_PLAN_TYPE",
    OVERRIDE_UPDATED_AT = "OVERRIDE_UPDATED_AT",
    OVERRIDE_EXPIRATION_DATE = "OVERRIDE_EXPIRATION_DATE",
    RELATIONSHIP_TO_SUBSCRIBER = "RELATIONSHIP_TO_SUBSCRIBER",
    SUBSCRIBER_NAME = "SUBSCRIBER_NAME",
    SUBSCRIBER_DOB = "SUBSCRIBER_DOB",
    SUBSCRIBER_MEMBER_ID = "SUBSCRIBER_MEMBER_ID",
    ELIGIBILITY_BEGIN_DATE = "ELIGIBILITY_BEGIN_DATE",
    ELIGIBILITY_END_DATE = "ELIGIBILITY_END_DATE",
    ELIGIBILITY_BEGIN_END_DATE = "ELIGIBILITY_BEGIN_END_DATE",
    MEMBER_OBLIGATION = "MEMBER_OBLIGATION",
    PAYER_OBLIGATION = "PAYER_OBLIGATION",
    THIRD_PARTY_PAYER = "THIRD_PARTY_PAYER",
    BENEFIT_STRUCTURE = "BENEFIT_STRUCTURE",
    PLAN_NAME = "PLAN_NAME",
    PLAN_TYPE = "PLAN_TYPE",
    PLAN_STATUS = "PLAN_STATUS",
    PLAN_BEGIN_DATE = "PLAN_BEGIN_DATE",
    PLAN_END_DATE = "PLAN_END_DATE",
    PLAN_BEGIN_END_DATE = "PLAN_BEGIN_END_DATE",
    INSURANCE_TYPE = "INSURANCE_TYPE",
    GROUP_NAME = "GROUP_NAME",
    GROUP_ID = "GROUP_ID",
    COVERAGE_STATUS = "COVERAGE_STATUS",
    DENIAL_RISK = "DENIAL_RISK",
    ALERTS = "ALERTS",
    REQUESTED_PAYER = "REQUESTED_PAYER",
    IDENTIFIED_PAYER = "IDENTIFIED_PAYER",
    COPAYMENT = "COPAYMENT",
    COINSURANCE = "COINSURANCE",
    TOTAL_DEDUCTIBLE = "TOTAL_DEDUCTIBLE",
    REMAINING_DEDUCTIBLE = "REMAINING_DEDUCTIBLE",
    TOTAL_OOP_MAX = "TOTAL_OOP_MAX",
    REMAINING_OOP_MAX = "REMAINING_OOP_MAX",
    NETWORK = "NETWORK",
    PROVIDER = "PROVIDER",
    DATE = "DATE",
    REMAINING_SESSIONS_BEFORE_DEDUCTIBLE = "REMAINING_SESSIONS_BEFORE_DEDUCTIBLE",
    REMAINING_SESSIONS_BEFORE_OOP_MAX = "REMAINING_SESSIONS_BEFORE_OOP_MAX",
    RESET_BENEFITS_STATUS = "RESET_BENEFITS_STATUS",
    RESET_BENEFITS_DIFFERENCE = "RESET_BENEFITS_DIFFERENCE",
    RESET_BENEFITS_COINSURANCE = "RESET_BENEFITS_COINSURANCE",
    RESET_BENEFITS_COPAYMENT = "RESET_BENEFITS_COPAYMENT",
    RESET_BENEFITS_TOTAL_DEDUCTIBLE = "RESET_BENEFITS_TOTAL_DEDUCTIBLE",
    RESET_BENEFITS_TOTAL_OOP_MAX = "RESET_BENEFITS_TOTAL_OOP_MAX",
    // MODALITY SPECIFIC
    PRIOR_AUTHORIZATION = "PRIOR_AUTHORIZATION",
    TOTAL_VISITS = "TOTAL_VISITS",
    REMAINING_VISITS = "REMAINING_VISITS",
}

export const POLICIES_COLUMNS_CONFIG = {
    get columnsConfig(): Record<
        PolicyColumnKey,
        IExtendedPolicyColumnConfiguration
    > {
        return {
            [PolicyColumnKey.MEMBER_ID]: {
                columnKey: PolicyColumnKey.MEMBER_ID,
                label: "Member ID",
                source: "policy",
                sourcePropertyPath: "memberId",
                widthClassName: "w-150px",
                mouseflowIgnore: true,
                isEnabled: true,
                isDefaultColumn: true,
                ignoreValueCapitalization: true,
            },
            [PolicyColumnKey.PATIENT_NAME]: {
                columnKey: PolicyColumnKey.PATIENT_NAME,
                label: "Patient Name",
                sortByField: AvailableCoverageHistorySortField.memberLastName,
                getCustomValue: data => {
                    const firstName =
                        data.coverageResult.demographics?.firstName ||
                        data.requestData.firstName
                    const lastName =
                        data.coverageResult.demographics?.lastName ||
                        data.requestData.lastName

                    return lastName && firstName
                        ? `${lastName}, ${firstName}`
                        : COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown
                },
                widthClassName: "w-200px",
                mouseflowIgnore: true,
                isEnabled: true,
                isDefaultColumn: true,
            },
            [PolicyColumnKey.PATIENT_DOB]: {
                columnKey: PolicyColumnKey.PATIENT_DOB,
                label: "Patient DOB",
                source: "policy",
                sourcePropertyPath: "memberDob",
                widthClassName: "w-115px",
                mouseflowIgnore: true,
                isEnabled: true,
                isDefaultColumn: true,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
            },
            [PolicyColumnKey.PATIENT_TYPE]: {
                columnKey: PolicyColumnKey.PATIENT_TYPE,
                label: "Patient Type",
                ignoreValueCapitalization: true,
                source: "coverageResult",
                sourcePropertyPath: "customerPatientType",
                widthClassName: "w-150px",
                isEnabled: false,
                isDefaultColumn: true,
                isOverridesEnabled: false,
                formatting: {
                    isPatientTypeMapped: true,
                },
            },
            [PolicyColumnKey.PATIENT_ID]: {
                columnKey: PolicyColumnKey.PATIENT_ID,
                label: "Patient ID",
                ignoreValueCapitalization: true,
                source: "coverageResult",
                sourcePropertyPath: "customerPatientId",
                widthClassName: "w-150px",
                isEnabled: false,
                isDefaultColumn: false,
                isReportsEnabled: true,
                isPlanYearResetsEnabled: true,
            },
            [PolicyColumnKey.PATIENT_NEXT_APPOINTMENT_DATE]: {
                columnKey: PolicyColumnKey.PATIENT_NEXT_APPOINTMENT_DATE,
                label: "Next Appointment Date",
                ignoreValueCapitalization: true,
                sortByField: AvailableCoverageHistorySortField.nextAppointment,
                source: "coverageResult",
                sourcePropertyPath: "customerPatientNextAppointmentDate",
                widthClassName: "w-200px",
                isEnabled: false,
                isDefaultColumn: false,
                isReportsEnabled: true,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
            },
            [PolicyColumnKey.OVERRIDE_UPDATED_AT]: {
                columnKey: PolicyColumnKey.OVERRIDE_UPDATED_AT,
                label: "Override Updated At",
                ignoreValueCapitalization: true,
                source: "coverageResult",
                sourcePropertyPath: "override.updatedAt",
                widthClassName: "w-200px",
                isEnabled: false,
                isDefaultColumn: false,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
                isReportsEnabled: true,
                isOverridesEnabled: true,
            },
            [PolicyColumnKey.OVERRIDE_EXPIRATION_DATE]: {
                columnKey: PolicyColumnKey.OVERRIDE_EXPIRATION_DATE,
                label: "Override Expiration Date",
                ignoreValueCapitalization: true,
                sortByField:
                    AvailableCoverageHistorySortField.overrideExpirationDate,
                source: "coverageResult",
                sourcePropertyPath: "override.expirationDate",
                widthClassName: "w-200px",
                isEnabled: false,
                isDefaultColumn: false,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
                isReportsEnabled: true,
                isOverridesEnabled: true,
            },
            [PolicyColumnKey.RELATIONSHIP_TO_SUBSCRIBER]: {
                columnKey: PolicyColumnKey.RELATIONSHIP_TO_SUBSCRIBER,
                label: "Relationship to Subscriber",
                source: "coverageResult",
                sourcePropertyPath: "relationshipToSubscriber",
                widthClassName: "w-175px",
                isEnabled: false,
                isDefaultColumn: false,
                mouseflowIgnore: true,
                addCheckerCSVDownload: true,
            },
            [PolicyColumnKey.SUBSCRIBER_NAME]: {
                columnKey: PolicyColumnKey.SUBSCRIBER_NAME,
                label: "Subscriber Name",
                getCustomValue: data => {
                    const firstName =
                        data.coverageResult.subscriberDemographics?.firstName
                    const lastName =
                        data.coverageResult.subscriberDemographics?.lastName

                    return lastName && firstName
                        ? `${lastName}, ${firstName}`
                        : ""
                },
                widthClassName: "w-200px",
                mouseflowIgnore: true,
                isEnabled: false,
                isDefaultColumn: false,
                addCheckerCSVDownload: true,
            },
            [PolicyColumnKey.SUBSCRIBER_DOB]: {
                columnKey: PolicyColumnKey.SUBSCRIBER_DOB,
                label: "Subscriber DOB",
                source: "coverageResult",
                sourcePropertyPath: "subscriberDemographics.dob",
                widthClassName: "w-115px",
                mouseflowIgnore: true,
                isEnabled: false,
                isDefaultColumn: false,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
                addCheckerCSVDownload: true,
            },
            [PolicyColumnKey.SUBSCRIBER_MEMBER_ID]: {
                columnKey: PolicyColumnKey.SUBSCRIBER_MEMBER_ID,
                label: "Subscriber Member ID",
                source: "coverageResult",
                sourcePropertyPath: "subscriberDemographics.memberId",
                widthClassName: "w-150px",
                mouseflowIgnore: true,
                isEnabled: false,
                isDefaultColumn: false,
                ignoreValueCapitalization: true,
                addCheckerCSVDownload: true,
            },
            [PolicyColumnKey.PLAN_STATUS]: {
                columnKey: PolicyColumnKey.PLAN_STATUS,
                label: "Plan Status",
                source: "coverageResult",
                sourcePropertyPath: "planStatus",
                widthClassName: "w-135px",
                isEnabled: false,
                isDefaultColumn: false,
                isHistoricalPoliciesEnabled: true,
                isOverridesEnabled: true,
                isPlanYearResetsEnabled: true,
                formatting: {
                    planStatus: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.planStatus",
                mouseflowIgnore: true,
            },
            [PolicyColumnKey.COVERAGE_STATUS]: {
                columnKey: PolicyColumnKey.COVERAGE_STATUS,
                label: "Coverage Status",
                source: "coverageResult",
                sourcePropertyPath: "modalityCoverageStatus",
                widthClassName: "w-175px",
                isEnabled: true,
                isDefaultColumn: true,
                ignoreValueCapitalization: true,
                formatOnEmptyColumn: true,
                formatting: {
                    modalityCoverageStatus: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath:
                    "override.modalityCoverageStatus",
            },
            [PolicyColumnKey.DENIAL_RISK]: {
                columnKey: PolicyColumnKey.DENIAL_RISK,
                label: "Denial Risk",
                source: "coverageResult",
                sourcePropertyPath: "flags",
                widthClassName: "w-150px",
                isEnabled: false,
                isDefaultColumn: false,
                formatting: {
                    denialRisk: true,
                },
            },
            [PolicyColumnKey.ALERTS]: {
                columnKey: PolicyColumnKey.ALERTS,
                label: "Alert(s)",
                sortByField: AvailableCoverageHistorySortField.flags,
                source: "coverageResult",
                sourcePropertyPath: "flags",
                widthClassName: "w-300px",
                isEnabled: true,
                isDefaultColumn: true,
                ignoreValueCapitalization: true,
                formatting: {
                    flagsList: true,
                },
                addCheckerCSVDownload: true,
                coverageCheckerCsvDownloadOrder: 12,
            },
            [PolicyColumnKey.REQUESTED_PAYER]: {
                columnKey: PolicyColumnKey.REQUESTED_PAYER,
                label: "Requested Payer",
                widthClassName: "w-275px",
                isEnabled: false,
                isDefaultColumn: false,
                ignoreValueCapitalization: true,
                getCustomValue: data => {
                    return data.requestPayer
                        ? `${
                              data.requestPayer.insuranceName
                          } (${data.requestPayer.payerId.toUpperCase()})`
                        : ""
                },
            },
            [PolicyColumnKey.IDENTIFIED_PAYER]: {
                columnKey: PolicyColumnKey.IDENTIFIED_PAYER,
                label: "Identified Payer",
                widthClassName: "w-275px",
                isEnabled: true,
                isHistoricalPoliciesEnabled: false,
                isOverridesEnabled: false,
                isDefaultColumn: true,
                ignoreValueCapitalization: true,
                getCustomValue: data => {
                    return data.responsePayer
                        ? `${
                              data.responsePayer.insuranceName
                          } (${data.responsePayer.payerId.toUpperCase()})`
                        : ""
                },
            },
            [PolicyColumnKey.NETWORK]: {
                columnKey: PolicyColumnKey.NETWORK,
                label: "Network",
                source: "coverageResult",
                sourcePropertyPath: "isInNetworkCheck",
                widthClassName: "w-100px",
                isEnabled: false,
                isHistoricalPoliciesEnabled: true,
                isDefaultColumn: false,
                ignoreValueCapitalization: true,
                formatting: {
                    isInNetworkCheckMapped: true,
                },
            },
            [PolicyColumnKey.PROVIDER]: {
                columnKey: PolicyColumnKey.PROVIDER,
                label: "Provider",
                source: "requestedHealthProvider",
                sourcePropertyPath: "name",
                alternativeSource: "requestData",
                alternativeSourcePropertyPath: "providerNpi",
                widthClassName: "w-235px",
                isEnabled: false,
                isDefaultColumn: false,
            },
            [PolicyColumnKey.DATE]: {
                columnKey: PolicyColumnKey.DATE,
                label: "Check Date",
                source: "coverageResult",
                sourcePropertyPath: "createdAt",
                widthClassName: "w-175px",
                isEnabled: false,
                isHistoricalPoliciesEnabled: true,
                isDefaultColumn: false,
                sortByField: AvailableCoverageHistorySortField.timestamp,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateTimeFormat,
                },
            },
            [PolicyColumnKey.ELIGIBILITY_BEGIN_DATE]: {
                columnKey: PolicyColumnKey.ELIGIBILITY_BEGIN_DATE,
                label: "Eligibility Begin Date",
                source: "coverageResult",
                sourcePropertyPath: "eligibilityBeginDate",
                widthClassName: "w-150px",
                mouseflowIgnore: true,
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath:
                    "override.eligibilityBeginDate",
                addCheckerCSVDownload: false,
            },
            [PolicyColumnKey.ELIGIBILITY_END_DATE]: {
                columnKey: PolicyColumnKey.ELIGIBILITY_END_DATE,
                label: "Eligibility End Date",
                source: "coverageResult",
                sourcePropertyPath: "eligibilityEndDate",
                widthClassName: "w-135px",
                mouseflowIgnore: true,
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.eligibilityEndDate",
                addCheckerCSVDownload: true,
            },
            [PolicyColumnKey.PLAN_BEGIN_DATE]: {
                columnKey: PolicyColumnKey.PLAN_BEGIN_DATE,
                label: "Plan Begin Date",
                source: "coverageResult",
                sourcePropertyPath: "planBeginDate",
                widthClassName: "w-135px",
                mouseflowIgnore: true,
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.planBeginDate",
            },
            [PolicyColumnKey.PLAN_END_DATE]: {
                columnKey: PolicyColumnKey.PLAN_END_DATE,
                label: "Plan End Date",
                source: "coverageResult",
                sourcePropertyPath: "planEndDate",
                widthClassName: "w-135px",
                mouseflowIgnore: true,
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                isPlanYearResetsEnabled: true,
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.planEndDate",
            },
            [PolicyColumnKey.RESET_BENEFITS_STATUS]: {
                columnKey: PolicyColumnKey.RESET_BENEFITS_STATUS,
                label: `${POLICIES_CONFIG.planYearResetsTargetYear} Benefits`,
                source: "coverageResult",
                sourcePropertyPath: "resetBenefitsStatus",
                widthClassName: "w-200px",
                mouseflowIgnore: false,
                isEnabled: true,
                isDefaultColumn: true,
                ignoreValueCapitalization: true,
                formatting: {
                    resetBenefitStatus: true,
                },
            },
            [PolicyColumnKey.RESET_BENEFITS_DIFFERENCE]: {
                columnKey: PolicyColumnKey.RESET_BENEFITS_DIFFERENCE,
                label: `${POLICIES_CONFIG.planYearResetsTargetYear} Differences`,
                source: "coverageResult",
                sourcePropertyPath: "resetBenefits",
                widthClassName: "w-225px",
                mouseflowIgnore: false,
                isEnabled: true,
                isDefaultColumn: true,
                emptyColumnValue: "None",
                ignoreValueCapitalization: true,
                formatting: {
                    resetBenefitDifference: true,
                },
            },
            [PolicyColumnKey.RESET_BENEFITS_COINSURANCE]: {
                columnKey: PolicyColumnKey.RESET_BENEFITS_COINSURANCE,
                label: `${POLICIES_CONFIG.planYearResetsTargetYear} Coinsurance`,
                source: "coverageResult",
                sourcePropertyPath: "resetBenefits.coinsurance",
                widthClassName: "w-115px",
                isEnabled: false,
                isDefaultColumn: false,
                formatting: {
                    suffixValue: "%",
                },
            },
            [PolicyColumnKey.RESET_BENEFITS_COPAYMENT]: {
                columnKey: PolicyColumnKey.RESET_BENEFITS_COPAYMENT,
                label: `${POLICIES_CONFIG.planYearResetsTargetYear} Copayment`,
                source: "coverageResult",
                sourcePropertyPath: "resetBenefits.copayment",
                widthClassName: "w-115px",
                isEnabled: false,
                isDefaultColumn: false,
                formatting: {
                    currency: true,
                    isCents: true,
                    showZeroFractionDigits: true,
                },
            },
            [PolicyColumnKey.RESET_BENEFITS_TOTAL_DEDUCTIBLE]: {
                columnKey: PolicyColumnKey.RESET_BENEFITS_TOTAL_DEDUCTIBLE,
                label: `${POLICIES_CONFIG.planYearResetsTargetYear} Deductible`,
                source: "coverageResult",
                sourcePropertyPath: "resetBenefits.deductible",
                widthClassName: "w-115px",
                isEnabled: false,
                isDefaultColumn: false,
                formatting: {
                    currency: true,
                    isCents: true,
                    showZeroFractionDigits: true,
                },
            },
            [PolicyColumnKey.RESET_BENEFITS_TOTAL_OOP_MAX]: {
                columnKey: PolicyColumnKey.RESET_BENEFITS_TOTAL_OOP_MAX,
                label: `${POLICIES_CONFIG.planYearResetsTargetYear} OOP Maximum`,
                source: "coverageResult",
                sourcePropertyPath: "resetBenefits.oopMax",
                widthClassName: "w-135px",
                isEnabled: false,
                isDefaultColumn: false,
                formatting: {
                    currency: true,
                    isCents: true,
                    showZeroFractionDigits: true,
                },
            },

            // MODALITY SPECIFIC
            [PolicyColumnKey.PRIOR_AUTHORIZATION]: {
                columnKey: PolicyColumnKey.PRIOR_AUTHORIZATION,
                isModalitySpecific: true,
                label: "Prior Authorization",
                source: "coverageResult",
                sourcePropertyPath: "priorAuthorization",
                widthClassName: "w-175px",
                isEnabled: false,
                isDefaultColumn: false,
                ignoreValueCapitalization: true,
                formatting: {
                    priorAuthorization: true,
                },
                addCheckerCSVDownload: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.SPECIALTY_SPECIFIC,
            },
            [PolicyColumnKey.TOTAL_VISITS]: {
                columnKey: PolicyColumnKey.TOTAL_VISITS,
                isModalitySpecific: true,
                label: "Total Visits",
                source: "coverageResult",
                sourcePropertyPath: "visitsTotal",
                widthClassName: "w-115px",
                isEnabled: false,
                isDefaultColumn: false,
                addCheckerCSVDownload: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.SPECIALTY_SPECIFIC,
            },
            [PolicyColumnKey.REMAINING_VISITS]: {
                columnKey: PolicyColumnKey.REMAINING_VISITS,
                isModalitySpecific: true,
                label: "Remaining Visits",
                source: "coverageResult",
                sourcePropertyPath: "visitsRemaining",
                widthClassName: "w-115px",
                isEnabled: false,
                isDefaultColumn: false,
                addCheckerCSVDownload: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.SPECIALTY_SPECIFIC,
            },

            // COVERAGE BREAKDOWN GENERAL - order matters unless overridden
            [PolicyColumnKey.PLAN_BEGIN_END_DATE]: {
                columnKey: PolicyColumnKey.PLAN_BEGIN_END_DATE,
                label: "Plan Begin-End Date",
                widthClassName: "w-275px",
                isEnabled: false,
                isDefaultColumn: false,
                ignoreValueCapitalization: true,
                mouseflowIgnore: true,
                getCustomValue: data => {
                    const { planBeginDate, planEndDate } = data.coverageResult

                    if (!planBeginDate && !planEndDate) {
                        return COVERAGE_CONFIG.defaultFallbackCoverageDataValueNA
                    }

                    return `${
                        planBeginDate
                            ? moment(planBeginDate).format(
                                  GENERAL_CONFIG.defaultMomentDateFormat
                              )
                            : COVERAGE_CONFIG.defaultFallbackCoverageDataValueNA
                    } - ${
                        planEndDate
                            ? moment(planEndDate).format(
                                  GENERAL_CONFIG.defaultMomentDateFormat
                              )
                            : COVERAGE_CONFIG.defaultFallbackCoverageDataValueNA
                    }`
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: [
                    "override.planBeginDate",
                    "override.planEndDate",
                ],
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_GENERAL,
                showCoverageBreakdownErrorMode: true,
            },
            [PolicyColumnKey.PLAN_TYPE]: {
                columnKey: PolicyColumnKey.PLAN_TYPE,
                label: "Plan Type",
                source: "coverageResult",
                sourcePropertyPath: "planType",
                widthClassName: "w-175px",
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                mouseflowIgnore: true,
                formatting: {
                    planType: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.planType",
                emptyColumnValue:
                    CHECKER_CONFIG.planTypeMapping[AvailablePlanType.UNKNOWN],
                ignoreValueCapitalization: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_GENERAL,
            },
            [PolicyColumnKey.PLAN_NAME]: {
                columnKey: PolicyColumnKey.PLAN_NAME,
                label: "Plan Name",
                source: "coverageResult",
                sourcePropertyPath: "planName",
                sortByField: AvailableCoverageHistorySortField.planName,
                widthClassName: "w-175px",
                isEnabled: true,
                isDefaultColumn: true,
                mouseflowIgnore: true,
                isOverridesEnabled: false,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_GENERAL,
                showCoverageBreakdownErrorMode: true,
            },
            [PolicyColumnKey.ELIGIBILITY_BEGIN_END_DATE]: {
                columnKey: PolicyColumnKey.ELIGIBILITY_BEGIN_END_DATE,
                label: "Eligibility Dates",
                widthClassName: "w-275px",
                isEnabled: false,
                isDefaultColumn: false,
                ignoreValueCapitalization: true,
                mouseflowIgnore: true,
                getCustomValue: data => {
                    const { eligibilityBeginDate, eligibilityEndDate } =
                        data.coverageResult

                    if (!eligibilityBeginDate && !eligibilityEndDate) {
                        return COVERAGE_CONFIG.defaultFallbackCoverageDataValueNA
                    }

                    return `${
                        eligibilityBeginDate
                            ? moment(eligibilityBeginDate).format(
                                  GENERAL_CONFIG.defaultMomentDateFormat
                              )
                            : COVERAGE_CONFIG.defaultFallbackCoverageDataValueNA
                    } - ${
                        eligibilityEndDate
                            ? moment(eligibilityEndDate).format(
                                  GENERAL_CONFIG.defaultMomentDateFormat
                              )
                            : COVERAGE_CONFIG.defaultFallbackCoverageDataValueNA
                    }`
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: [
                    "override.eligibilityBeginDate",
                    "override.eligibilityEndDate",
                ],
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_GENERAL,
                showCoverageBreakdownErrorMode: true,
            },
            [PolicyColumnKey.GROUP_NAME]: {
                columnKey: PolicyColumnKey.GROUP_NAME,
                label: "Group Name",
                source: "coverageResult",
                sourcePropertyPath: "groupName",
                widthClassName: "w-135px",
                mouseflowIgnore: true,
                isEnabled: false,
                isDefaultColumn: false,
                ignoreValueCapitalization: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_GENERAL,
                showCoverageBreakdownErrorMode: true,
            },
            [PolicyColumnKey.GROUP_ID]: {
                columnKey: PolicyColumnKey.GROUP_ID,
                label: "Group ID",
                source: "coverageResult",
                sourcePropertyPath: "groupId",
                widthClassName: "w-135px",
                mouseflowIgnore: true,
                isEnabled: false,
                isDefaultColumn: false,
                ignoreValueCapitalization: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_GENERAL,
            },
            [PolicyColumnKey.INSURANCE_TYPE]: {
                columnKey: PolicyColumnKey.INSURANCE_TYPE,
                label: "Insurance Type",
                ignoreValueCapitalization: true,
                source: "coverageResult",
                sourcePropertyPath: "insuranceType",
                widthClassName: "w-150px",
                isEnabled: false,
                isDefaultColumn: false,
                addCheckerCSVDownload: true,
                mouseflowIgnore: true,
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_GENERAL,
            },

            // COVERAGE BREAKDOWN THIRD PARTY PAYER - order matters unless overridden
            [PolicyColumnKey.THIRD_PARTY_PAYER]: {
                columnKey: PolicyColumnKey.THIRD_PARTY_PAYER,
                label: "Third Party Payer",
                source: "coverageResult",
                sourcePropertyPath: "thirdPartyPayer",
                widthClassName: "w-200px",
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                ignoreValueCapitalization: true,
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.thirdPartyPayer",
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_THIRD_PARTY,
                coverageBreakdownLabelOverride: "Payer",
            },

            // COVERAGE BREAKDOWN ADDITIONAL_POLICY_PAYER - order matters unless overridden
            [PolicyColumnKey.ADDITIONAL_POLICY_PAYER]: {
                columnKey: PolicyColumnKey.ADDITIONAL_POLICY_PAYER,
                label: "Additional Policy Payer",
                ignoreValueCapitalization: true,
                source: "coverageResult",
                sourcePropertyPath: "additionalPolicy.insurer",
                widthClassName: "w-200px",
                isEnabled: false,
                isDefaultColumn: false,
                addCheckerCSVDownload: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_ADDITIONAL_POLICY,
                coverageBreakdownLabelOverride: "Payer",
            },
            [PolicyColumnKey.ADDITIONAL_POLICY_MEMBER_ID]: {
                columnKey: PolicyColumnKey.ADDITIONAL_POLICY_MEMBER_ID,
                label: "Additional Policy Member ID",
                ignoreValueCapitalization: true,
                source: "coverageResult",
                sourcePropertyPath: "additionalPolicy.memberId",
                widthClassName: "w-150px",
                isEnabled: false,
                isDefaultColumn: false,
                addCheckerCSVDownload: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_ADDITIONAL_POLICY,
                coverageBreakdownLabelOverride: "Member ID",
            },
            [PolicyColumnKey.ADDITIONAL_POLICY_TYPE]: {
                columnKey: PolicyColumnKey.ADDITIONAL_POLICY_TYPE,
                label: "Additional Policy Type",
                ignoreValueCapitalization: true,
                source: "coverageResult",
                sourcePropertyPath: "additionalPolicy.type",
                widthClassName: "w-150px",
                isEnabled: false,
                isDefaultColumn: false,
                addCheckerCSVDownload: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_ADDITIONAL_POLICY,
                coverageBreakdownLabelOverride: "Policy Type",
            },
            [PolicyColumnKey.ADDITIONAL_POLICY_TYPE_PLAN_TYPE]: {
                columnKey: PolicyColumnKey.ADDITIONAL_POLICY_TYPE_PLAN_TYPE,
                label: "Additional Policy Plan Type",
                source: "coverageResult",
                sourcePropertyPath: "additionalPolicy.planType",
                widthClassName: "w-175px",
                isEnabled: false,
                isDefaultColumn: false,
                mouseflowIgnore: true,
                addCheckerCSVDownload: true,
                formatting: {
                    planType: true,
                },
                emptyColumnValue:
                    CHECKER_CONFIG.planTypeMapping[AvailablePlanType.UNKNOWN],
                ignoreValueCapitalization: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_ADDITIONAL_POLICY,
                coverageBreakdownLabelOverride: "Plan Type",
            },

            // COVERAGE BREAKDOWN FINANCIALS - order matters unless overridden
            [PolicyColumnKey.BENEFIT_STRUCTURE]: {
                columnKey: PolicyColumnKey.BENEFIT_STRUCTURE,
                label: "Benefit Structure",
                source: "coverageResult",
                sourcePropertyPath: "override.benefitStructure",
                alternativeSource: "coverageResult",
                alternativeSourcePropertyPath: "benefitStructure",
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.benefitStructure",
                widthClassName: "w-200px",
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                ignoreValueCapitalization: true,
                addCheckerCSVDownload: true,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_FINANCIALS,
            },
            [PolicyColumnKey.COINSURANCE]: {
                columnKey: PolicyColumnKey.COINSURANCE,
                label: "Coinsurance",
                source: "coverageResult",
                sourcePropertyPath: "coinsurance",
                widthClassName: "w-115px",
                isEnabled: true,
                isDefaultColumn: true,
                isPlanYearResetsEnabled: false,
                formatting: {
                    suffixValue: "%",
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.coinsurance",
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_FINANCIALS,
                coverageBreakdownEmptyValueTooltip:
                    TOOLTIPS_CONFIG.coverageBreakdown.copayCoinsuranceUnknown,
            },
            [PolicyColumnKey.COPAYMENT]: {
                columnKey: PolicyColumnKey.COPAYMENT,
                label: "Copayment",
                source: "coverageResult",
                sourcePropertyPath: "copayment",
                widthClassName: "w-115px",
                isEnabled: true,
                isDefaultColumn: true,
                isPlanYearResetsEnabled: false,
                formatting: {
                    currency: true,
                    isCents: true,
                    showZeroFractionDigits: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.copayment",
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_FINANCIALS,
                coverageBreakdownEmptyValueTooltip:
                    TOOLTIPS_CONFIG.coverageBreakdown.copayCoinsuranceUnknown,
            },
            [PolicyColumnKey.TOTAL_DEDUCTIBLE]: {
                columnKey: PolicyColumnKey.TOTAL_DEDUCTIBLE,
                label: "Deductible",
                source: "coverageResult",
                sourcePropertyPath: "deductible",
                widthClassName: "w-115px",
                isEnabled: false,
                isHistoricalPoliciesEnabled: true,
                isOverridesEnabled: true,
                isDefaultColumn: false,
                formatting: {
                    currency: true,
                    isCents: true,
                    showZeroFractionDigits: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.deductible",
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_FINANCIALS,
            },
            [PolicyColumnKey.REMAINING_DEDUCTIBLE]: {
                columnKey: PolicyColumnKey.REMAINING_DEDUCTIBLE,
                label: "Remaining Deductible",
                source: "coverageResult",
                sourcePropertyPath: "remainingDeductible",
                widthClassName: "w-150px",
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                formatting: {
                    currency: true,
                    isCents: true,
                    showZeroFractionDigits: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.remainingDeductible",
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_FINANCIALS,
            },
            [PolicyColumnKey.TOTAL_OOP_MAX]: {
                columnKey: PolicyColumnKey.TOTAL_OOP_MAX,
                label: "OOP Maximum",
                source: "coverageResult",
                sourcePropertyPath: "oopMax",
                widthClassName: "w-135px",
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                formatting: {
                    currency: true,
                    isCents: true,
                    showZeroFractionDigits: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.oopMax",
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_FINANCIALS,
            },
            [PolicyColumnKey.REMAINING_OOP_MAX]: {
                columnKey: PolicyColumnKey.REMAINING_OOP_MAX,
                label: "Remaining OOP Maximum",
                source: "coverageResult",
                sourcePropertyPath: "remainingOopMax",
                widthClassName: "w-180px",
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                formatting: {
                    currency: true,
                    isCents: true,
                    showZeroFractionDigits: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.remainingOopMax",
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_FINANCIALS,
                coverageBreakdownLabelOverride: "Remaining OOP Max",
            },

            // COVERAGE BREAKDOWN OBLIGATION - order matters unless overridden
            [PolicyColumnKey.MEMBER_OBLIGATION]: {
                columnKey: PolicyColumnKey.MEMBER_OBLIGATION,
                label: "Member Obligation",
                source: "coverageResult",
                sourcePropertyPath: "memberObligation",
                widthClassName: "w-135px",
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                formatting: {
                    currency: true,
                    isCents: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.memberObligation",
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_OBLIGATION,
            },
            [PolicyColumnKey.PAYER_OBLIGATION]: {
                columnKey: PolicyColumnKey.PAYER_OBLIGATION,
                label: "Payer Obligation",
                source: "coverageResult",
                sourcePropertyPath: "payerObligation",
                widthClassName: "w-135px",
                isEnabled: false,
                isDefaultColumn: false,
                isOverridesEnabled: true,
                formatting: {
                    currency: true,
                    isCents: true,
                },
                isHighlightedSource: "coverageResult",
                isHighlightedSourcePropertyPath: "override.payerObligation",
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_OBLIGATION,
            },
            [PolicyColumnKey.REMAINING_SESSIONS_BEFORE_DEDUCTIBLE]: {
                columnKey: PolicyColumnKey.REMAINING_SESSIONS_BEFORE_DEDUCTIBLE,
                label: "Remaining Sessions before Deductible",
                source: "coverageResult",
                sourcePropertyPath: "remainingSessionsBeforeDeductible",
                widthClassName: "w-135px",
                isEnabled: false,
                isDefaultColumn: false,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_OBLIGATION,
            },
            [PolicyColumnKey.REMAINING_SESSIONS_BEFORE_OOP_MAX]: {
                columnKey: PolicyColumnKey.REMAINING_SESSIONS_BEFORE_OOP_MAX,
                label: "Remaining Session before OOP Max",
                source: "coverageResult",
                sourcePropertyPath: "remainingSessionsBeforeOopMax",
                widthClassName: "w-135px",
                isEnabled: false,
                isDefaultColumn: false,
                // Coverage breakdown
                coverageBreakdownSection:
                    CoverageBreakdownSection.POLICY_OBLIGATION,
            },
        }
    },

    // In the future just add needed columns here and it will propagate to all places:
    // download csv from manual, reports download, coverage breakdown, reports view, search policies view
    get modalitySpecificColumns(): Record<
        PolicyModality,
        IExtendedPolicyColumnConfiguration[]
    > {
        return {
            [PolicyModality.MENTAL_HEALTH]: [],
            [PolicyModality.EVALUATION_MANAGEMENT]: [],
            [PolicyModality.PHYSICAL_THERAPY]: [
                POLICIES_COLUMNS_CONFIG.columnsConfig.PRIOR_AUTHORIZATION,
                POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_VISITS,
                POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_VISITS,
            ],
        }
    },

    get reportsPoliciesColumns(): IPolicyColumnConfiguration[] {
        return [
            POLICIES_COLUMNS_CONFIG.columnsConfig.COVERAGE_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.DENIAL_RISK,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ALERTS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_DOB,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_NEXT_APPOINTMENT_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REQUESTED_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.IDENTIFIED_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_BEGIN_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_END_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.INSURANCE_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.GROUP_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.GROUP_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ELIGIBILITY_END_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.OVERRIDE_UPDATED_AT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.OVERRIDE_EXPIRATION_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COPAYMENT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COINSURANCE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.NETWORK,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PROVIDER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.THIRD_PARTY_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ADDITIONAL_POLICY_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ADDITIONAL_POLICY_MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.RELATIONSHIP_TO_SUBSCRIBER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_DOB,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.MEMBER_OBLIGATION,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PAYER_OBLIGATION,
            POLICIES_COLUMNS_CONFIG.columnsConfig.BENEFIT_STRUCTURE,
        ]
    },

    get historicalPoliciesColumns(): IPolicyColumnConfiguration[] {
        return [
            POLICIES_COLUMNS_CONFIG.columnsConfig.MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_DOB,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COVERAGE_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_BEGIN_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_END_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.INSURANCE_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.NETWORK,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COPAYMENT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COINSURANCE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.OVERRIDE_UPDATED_AT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.OVERRIDE_EXPIRATION_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ELIGIBILITY_END_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REQUESTED_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.IDENTIFIED_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ADDITIONAL_POLICY_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ADDITIONAL_POLICY_MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_NEXT_APPOINTMENT_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.RELATIONSHIP_TO_SUBSCRIBER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_DOB,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.MEMBER_OBLIGATION,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PAYER_OBLIGATION,
            POLICIES_COLUMNS_CONFIG.columnsConfig.THIRD_PARTY_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.BENEFIT_STRUCTURE,
        ]
    },

    get overridesPoliciesColumns(): IPolicyColumnConfiguration[] {
        return [
            POLICIES_COLUMNS_CONFIG.columnsConfig.MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ALERTS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_DOB,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.OVERRIDE_UPDATED_AT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.OVERRIDE_EXPIRATION_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_NEXT_APPOINTMENT_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COVERAGE_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.INSURANCE_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COPAYMENT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COINSURANCE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.BENEFIT_STRUCTURE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.MEMBER_OBLIGATION,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PAYER_OBLIGATION,
            POLICIES_COLUMNS_CONFIG.columnsConfig.NETWORK,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REQUESTED_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.IDENTIFIED_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.THIRD_PARTY_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ADDITIONAL_POLICY_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ADDITIONAL_POLICY_MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_BEGIN_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_END_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ELIGIBILITY_BEGIN_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ELIGIBILITY_END_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.RELATIONSHIP_TO_SUBSCRIBER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_DOB,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.DATE,
        ]
    },

    get planYearResetsPoliciesColumns(): IPolicyColumnConfiguration[] {
        return [
            POLICIES_COLUMNS_CONFIG.columnsConfig.COVERAGE_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.RESET_BENEFITS_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.RESET_BENEFITS_DIFFERENCE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_BEGIN_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_END_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_NEXT_APPOINTMENT_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_DOB,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REQUESTED_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.IDENTIFIED_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.INSURANCE_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.NETWORK,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COPAYMENT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.RESET_BENEFITS_COPAYMENT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COINSURANCE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.RESET_BENEFITS_COINSURANCE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig
                .RESET_BENEFITS_TOTAL_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.RESET_BENEFITS_TOTAL_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.OVERRIDE_UPDATED_AT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.OVERRIDE_EXPIRATION_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ELIGIBILITY_END_DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.DATE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ADDITIONAL_POLICY_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.ADDITIONAL_POLICY_MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.RELATIONSHIP_TO_SUBSCRIBER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_DOB,
            POLICIES_COLUMNS_CONFIG.columnsConfig.SUBSCRIBER_MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.MEMBER_OBLIGATION,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PAYER_OBLIGATION,
            POLICIES_COLUMNS_CONFIG.columnsConfig.THIRD_PARTY_PAYER,
            POLICIES_COLUMNS_CONFIG.columnsConfig.BENEFIT_STRUCTURE,
        ]
    },

    get overridesPreviewPoliciesColumns(): IPolicyColumnConfiguration[] {
        return [
            POLICIES_COLUMNS_CONFIG.columnsConfig.MEMBER_ID,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PATIENT_DOB,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COVERAGE_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_STATUS,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_NAME,
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.INSURANCE_TYPE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COPAYMENT,
            POLICIES_COLUMNS_CONFIG.columnsConfig.COINSURANCE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.TOTAL_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_DEDUCTIBLE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.REMAINING_OOP_MAX,
            POLICIES_COLUMNS_CONFIG.columnsConfig.BENEFIT_STRUCTURE,
            POLICIES_COLUMNS_CONFIG.columnsConfig.THIRD_PARTY_PAYER,
        ]
    },
}
