/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import {
    ButtonElement,
    PrimaryText,
    IStepperStepProps,
    InputElement,
    VALIDATION_CONFIG,
    CheckboxElement,
} from "nirvana-react-elements"

import { useAppSelector } from "../../../../store/selectors/app.selector"
import { checkerSelector } from "../../../../store/selectors/checker.selector"

export const MemberInformationStep: React.FunctionComponent<
    IStepperStepProps
> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
    } = useForm()

    const {
        intakeCheck: { inputData: stateInputData },
    } = useAppSelector(checkerSelector)

    const formDefaultValues = useMemo<
        Partial<IIntakeCheckerMemberInformationInputData>
    >(() => {
        const stepperDefaultValues = props.defaultValues as
            | IIntakeCheckerMemberInformationInputData
            | undefined

        return {
            memberId:
                stepperDefaultValues?.memberId ||
                stateInputData?.memberInformation?.memberId,
            zipCode:
                stepperDefaultValues?.zipCode ||
                stateInputData?.memberInformation?.zipCode,
        }
    }, [props.defaultValues, stateInputData])

    const payerSupportsDiscover = useMemo<boolean>(() => {
        return !!(props.fullStepperData as IIntakeCheckerInputData)
            ?.patientInformation?.payer?.isDiscoverSupported
    }, [props.fullStepperData])

    const [isMemberIdMissing, setIsMemberIdMissing] = useState<boolean>(
        payerSupportsDiscover && !!formDefaultValues.zipCode
    )

    const memberIdValidation = () => {
        return () => {
            const dataWatcher = watch()

            return isMemberIdMissing && payerSupportsDiscover
                ? true
                : !!dataWatcher?.memberId || "Member ID is required"
        }
    }

    const zipCodeValidation = () => {
        return () => {
            const dataWatcher = watch()

            return isMemberIdMissing && payerSupportsDiscover
                ? !!dataWatcher?.zipCode || "ZIP Code is required"
                : true
        }
    }

    const onSubmit = (data: any) => {
        const typedData = data as IIntakeCheckerMemberInformationInputData

        const stepData: IIntakeCheckerMemberInformationInputData = {
            memberId:
                isMemberIdMissing && payerSupportsDiscover
                    ? undefined
                    : typedData.memberId,
            zipCode:
                !isMemberIdMissing || !payerSupportsDiscover
                    ? undefined
                    : typedData.zipCode,
        }

        props.onStepSubmit(stepData)
    }

    return (
        <div className="relative">
            <PrimaryText size={20} lineHeight={24}>
                Please provide the patient’s Member ID
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <div
                    className="
                        flex mt-44px
                        md:block
                    "
                >
                    <div
                        className="
                            mr-24px flex-1
                            md:mr-0px
                        "
                    >
                        <InputElement
                            className={`
                                ${isMemberIdMissing ? "opacity-50" : ""}
                            `}
                            label="Member ID"
                            placeholder="E.g. 83259320"
                            name="memberId"
                            reactHookErrors={errors}
                            reactHookControl={control}
                            reactHookValidations={{
                                maxLength: VALIDATION_CONFIG.maxLength,
                                validate: memberIdValidation(),
                            }}
                            defaultValue={formDefaultValues.memberId}
                            disabled={isMemberIdMissing}
                            isLabelStatic
                        />

                        {payerSupportsDiscover && (
                            <CheckboxElement
                                className="mt-12px"
                                name="isMemberIdMissing"
                                defaultChecked={isMemberIdMissing}
                                onChange={setIsMemberIdMissing}
                                label={
                                    <PrimaryText>
                                        I do not have their Member ID
                                    </PrimaryText>
                                }
                            />
                        )}
                    </div>

                    {payerSupportsDiscover && (
                        <InputElement
                            className={`
                                w-140px mr-24px
                                md:w-full md:mt-44px
                                ${!isMemberIdMissing ? "opacity-50" : ""}
                            `}
                            label="ZIP"
                            placeholder="10000"
                            name="zipCode"
                            reactHookControl={control}
                            reactHookValidations={{
                                pattern: VALIDATION_CONFIG.zipShort,
                                validate: zipCodeValidation(),
                            }}
                            reactHookErrors={errors}
                            defaultValue={formDefaultValues.zipCode}
                            disabled={!isMemberIdMissing}
                            isLabelStatic
                        />
                    )}

                    <div className="flex-1" />
                </div>

                <div className="mt-24px flex items-center justify-between">
                    <ButtonElement
                        label="Back"
                        type="default"
                        htmlType="button"
                        size="large"
                        onClick={props.onStepBack}
                    />

                    <ButtonElement
                        label="Run Check"
                        type="primary"
                        htmlType="submit"
                        size="large"
                    />
                </div>
            </form>
        </div>
    )
}
