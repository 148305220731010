/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PrimaryText, StepperElement } from "nirvana-react-elements"
import { useNavigate } from "react-router-dom"

import { STEPPER_CONFIG } from "../../../../config/stepper.config"
import { useAppSelector } from "../../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../../../store/appDispatch.hook"
import { checkerRunIntakeCheck } from "../../../../store/thunks/checker.thunks"
import { ROUTES_CONFIG } from "../../../../config/routes.config"
import { GENERAL_CONFIG } from "../../../../config/general.config"
import { checkerSelector } from "../../../../store/selectors/checker.selector"
import { CoverageCheckerRunningState } from "../../../../config/checker.config"
import { IntakeCheckerResultsComponent } from "./intakeCheckerResults.component"

export const IntakeCheckerComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const checkerState = useAppSelector(checkerSelector)

    const isResultsView = useMemo<boolean>(() => {
        return (
            checkerState.runningState ===
            CoverageCheckerRunningState.RESULTS_MANUAL
        )
    }, [checkerState.runningState])

    const onSubmit = (data: IIntakeCheckerInputData) => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            checkerRunIntakeCheck({
                practice: selectedPracticeRole.practice,
                payload: {
                    requestData: data,
                },
                onSuccess: () => {
                    // Add random part to the url, so we can safely catch browser back button then
                    // And if back button is clicked from showing results -> it will initiate "run new checks"
                    // Acts as history.push
                    navigate(
                        `${ROUTES_CONFIG.coverageCheckerUrl}?${
                            GENERAL_CONFIG.urlSearchParamsKeys.results
                        }=${new Date().getTime()}`
                    )
                },
            })
        )
    }

    const getSpecialStepBottomContent = (
        currentStepIndex: number
    ): JSX.Element | null => {
        return (
            <PrimaryText className="absolute horizontal-center z-10 bottom-8px">
                Part {currentStepIndex + 1} of{" "}
                {STEPPER_CONFIG.intakeCheckerSteps.length}
            </PrimaryText>
        )
    }

    const getContentBasedOnRunningState = (): JSX.Element | null => {
        switch (checkerState.runningState) {
            case CoverageCheckerRunningState.INPUT_MANUAL:
                return (
                    <div className="mt-24px">
                        <StepperElement
                            steps={STEPPER_CONFIG.intakeCheckerSteps}
                            onStepperSubmit={onSubmit}
                            getSpecialStepBottomContent={
                                getSpecialStepBottomContent
                            }
                        />
                    </div>
                )

            case CoverageCheckerRunningState.RESULTS_MANUAL:
                return <IntakeCheckerResultsComponent />

            default:
                return null
        }
    }

    return (
        <div
            className={`
                ${props.className}
                relative
                ${
                    !isResultsView
                        ? "max-w-800px p-16px rounded-8px bg-brand-warmLight025"
                        : ""
                }
            `}
        >
            {!isResultsView && (
                <PrimaryText typography="h3">
                    Run an eligibility check
                </PrimaryText>
            )}

            {getContentBasedOnRunningState()}
        </div>
    )
}
