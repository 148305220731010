import { IStepperStep } from "nirvana-react-elements"

// REGISTER
import { EmailStep } from "../components/auth/register/steps/email.step"
import { PasswordStep } from "../components/auth/register/steps/password.step"

// INTAKE CHECKER
import { PatientInformationStep } from "../components/coverageChecker/manual/intake/patientInformation.step"
import { MemberInformationStep } from "../components/coverageChecker/manual/intake/memberInformation.step"

export const STEPPER_CONFIG = {
    get registerSteps(): IStepperStep[] {
        return [
            {
                key: "emailData",
                stepComponent: EmailStep,
            },
            {
                key: "passwordData",
                stepComponent: PasswordStep,
            },
        ]
    },

    get intakeCheckerSteps(): IStepperStep[] {
        return [
            {
                key: "patientInformation",
                stepComponent: PatientInformationStep,
            },
            {
                key: "memberInformation",
                stepComponent: MemberInformationStep,
            },
        ]
    },
}
