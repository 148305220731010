/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import {
    ButtonElement,
    DatePickerElement,
    NIRVANA_COLORS,
    NIRVANA_THEME,
    PageHelmetElement,
    PrimaryText,
    SpinnerElement,
    TooltipElement,
    useIsMobile,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"
import { useNavigate, useSearchParams } from "react-router-dom"
import { FormProvider } from "react-hook-form"

import {
    policiesGetSinglePolicy,
    policiesReloadList,
    policiesRetrySinglePolicy,
} from "../../../store/thunks/policies.thunks"
import {
    POLICIES_CONFIG,
    PoliciesViewType,
} from "../../../config/policies.config"
import { COVERAGE_CONFIG } from "../../../config/coverage.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { policiesSetSelectedItem } from "../../../store/slices/policies.slice"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { UtilHelper } from "../../../helpers/util.helper"
import { FlagsResolutionComponent } from "../flags/flagsResolution.component"
import { CoverageResultGeneralComponent } from "../../coverageChecker/result/coverageResultGeneral.component"
import { CoverageResultBreakdownWrapperComponent } from "../../coverageChecker/result/coverageResultBreakdownWrapper.component"
import { coverageOverridesSetSelectedPolicyOverride } from "../../../store/slices/coverageOverrides.slice"
import { coverageOverridesSelector } from "../../../store/selectors/coverageOverrides.selector"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { CoverageResultFlagsComponent } from "../flags/coverageResultFlags.component"
import { useSingleOverride } from "../../../hooks/singleOverride.hooks"
import { PolicyCommentsComponent } from "../comments/policyComments.component"
import { CoverageHistoryHelper } from "../../../helpers/coverageHistory.helper"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { TOOLTIPS_CONFIG } from "../../../config/tooltips.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { CoverageCheckerMode } from "../../../config/checker.config"

import arrowLeftIcon from "../../../assets/images/icons/arrow-left-dark.svg"
import editIcon from "../../../assets/images/icons/edit-dark.svg"
import refreshIcon from "../../../assets/images/icons/refresh-dark.svg"

export const SinglePolicyViewComponent: React.FunctionComponent<
    ISinglePolicyViewComponentProps
> = props => {
    const isTablet = useIsMobile(NIRVANA_THEME.mediaQueries.md)

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const overridesState = useAppSelector(coverageOverridesSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const {
        selectedItem,
        historyItems,
        reportsItems,
        overridesItems,
        planYearResetsItems,
    } = useAppSelector(policiesSelector)

    const isRetryingCoverageCheckLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isRetrySinglePolicyLoading()
    }, [runtimeState.isLoading])

    const hasRunChecksPermission = useHasPermissions(
        AvailableCoveragePortalPermission.runCoverageChecks,
        undefined,
        false
    )

    // Re-trying only allowed for specific original status codes
    const isRetryCheckAllowedByStatusCode = useMemo<boolean>(() => {
        return (
            !!selectedItem?.coverageResult.responseStatus &&
            POLICIES_CONFIG.singlePolicyHistoryAllowedRunCheckStatusCodes.includes(
                selectedItem.coverageResult.responseStatus
            )
        )
    }, [selectedItem])

    const items = useMemo<ICoverageCheckHistory[]>(() => {
        switch (props.viewType) {
            case PoliciesViewType.HISTORY_SEARCH:
                return historyItems

            case PoliciesViewType.REPORTS:
                return reportsItems

            case PoliciesViewType.OVERRIDES:
                return overridesItems

            case PoliciesViewType.PLAN_YEAR_RESETS:
                return planYearResetsItems
        }
    }, [props.viewType])

    const similarPoliciesUrl = useMemo<string | null>(() => {
        const { coverageResult } = selectedItem || {}

        if (
            !coverageResult?.groupName &&
            !coverageResult?.planName &&
            !coverageResult?.groupId
        ) {
            return null
        }

        const filtersData: IPoliciesListFiltersData = {
            groupId: coverageResult.groupId || undefined,
            groupName: coverageResult.groupName || undefined,
            planName: coverageResult.planName || undefined,
        }

        return `${ROUTES_CONFIG.reportsUrl}?${
            GENERAL_CONFIG.urlSearchParamsKeys.filters
        }=${JSON.stringify(filtersData)}`
    }, [selectedItem])

    const selectedItemPatientIdentificationData =
        useMemo<IPatientIdentificationData | null>(() => {
            return CoverageHistoryHelper.getPatientIdentificationData(
                selectedItem
            )
        }, [selectedItem])

    // Get single coverage check from server
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        const isSameSelected =
            selectedItem &&
            props.nirvanaRequestId === selectedItem.coverageResult.resultId

        // IF selected other item -> reset it
        // Also reset selected override
        if (!isSameSelected) {
            resetState()
        }

        // Get fresh item from server in any case for flags
        if (!isSameSelected || props.isFlagResolution) {
            getSinglePolicy()
        }
    }, [props.nirvanaRequestId, selectedPracticeRole?.practice.id])

    const resetState = () => {
        dispatch(policiesSetSelectedItem(null))
        dispatch(coverageOverridesSetSelectedPolicyOverride(null))
    }

    const getSinglePolicy = (
        withPolicyDataReFetch?: boolean,
        onSuccess?: () => void
    ) => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            policiesGetSinglePolicy({
                practice: selectedPracticeRole.practice,
                payload: {
                    retryMaxCount: 15,
                    nirvanaRequestId: props.nirvanaRequestId,
                    withPolicyDataReFetch,
                },
                onSuccess: () => {
                    onSuccess?.()

                    if (
                        searchParams.get(
                            GENERAL_CONFIG.urlSearchParamsKeys
                                .reloadPoliciesList
                        )
                    ) {
                        reloadPoliciesList()
                    }
                },
            })
        )
    }

    const onGoBack = () => {
        // If already loaded needed items
        const ignoreListReload = props.isFlagResolution ? false : !!items.length

        navigate(
            PoliciesHelper.getPoliciesListBaseUrl(props.viewType) +
                (ignoreListReload
                    ? `?${GENERAL_CONFIG.urlSearchParamsKeys.ignoreListReload}=true`
                    : "")
        )
    }

    // reload list with same pagination
    // so new overridden value is in the list
    const reloadPoliciesList = () => {
        if (!selectedPracticeRole?.practice) {
            return
        }

        dispatch(
            policiesReloadList({
                practice: selectedPracticeRole.practice,
                payload: {
                    policiesViewType: props.viewType,
                },
            })
        )
    }

    // Navigate to new policy request ID
    const navigateToPolicyByRequestId = (
        nirvanaRequestId: string,
        withPoliciesListReload = true
    ) => {
        const neededUrl =
            PoliciesHelper.getSinglePolicyBaseUrl(props.viewType).replace(
                ":nirvanaRequestId",
                nirvanaRequestId
            ) +
            (withPoliciesListReload
                ? `?${GENERAL_CONFIG.urlSearchParamsKeys.reloadPoliciesList}=true`
                : "")

        navigate(neededUrl)
    }

    const onSmartScanSuccess = (result: ICoverageResult) => {
        result.requestId && navigateToPolicyByRequestId(result.requestId)
    }

    const {
        overridesFormMethods,
        isOverridesEnabled,
        isOverrideModeActive,
        setIsOverrideModeActive,
        overridesFormKey,
        coverageCheckIdentificationData: selectedItemIdentificationData,
        canOverridePolicyCoverage,
        isDeleteOverrideLoading,
        isCreateOverrideLoading,
        isOverrideActionsDisabled,
        onDeleteOverride,
        onResetOverride,
        onSaveOverride,
        getSingleOverride,
        onUseSuggestedOverrideExpiration,
        isFormSubmitDisabled,
        suggestedOverrideExpirationDate,
        minOverrideExpirationDate,
        dataWatcher: overridesDataWatcher,
    } = useSingleOverride(
        selectedItem,
        overridesState.selectedPolicyOverride,
        props.nirvanaRequestId,
        getSinglePolicy,
        reloadPoliciesList,
        navigateToPolicyByRequestId
    )

    // Try to get single override for coverage check
    useEffect(() => {
        const isSameSelected =
            selectedItem &&
            props.nirvanaRequestId === selectedItem.coverageResult.resultId

        if (!isSameSelected || !selectedItemIdentificationData) {
            return
        }

        getSingleOverride()
    }, [selectedItemIdentificationData, selectedPracticeRole?.practice.id])

    const retryCoverageCheck = async () => {
        if (!isRetryCheckAllowedByStatusCode || !selectedPracticeRole) {
            return
        }

        const result = await dispatch(
            policiesRetrySinglePolicy({
                practice: selectedPracticeRole.practice,
                payload: {
                    nirvanaRequestId: props.nirvanaRequestId,
                },
            })
        ).unwrap()

        result?.requestId && navigateToPolicyByRequestId(result.requestId)
    }

    return (
        <div className="relative">
            <PageHelmetElement
                title="Policy"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {selectedItem ? (
                <>
                    <FormProvider {...overridesFormMethods}>
                        <div
                            className="
                                flex items-center px-16px py-16px
                                border-b border-solid border-brand-warmLight
                                md:block
                            "
                        >
                            <div className="flex-1 mr-16px flex items-start">
                                <div
                                    className="px-8px cursor-pointer relative top-8px mr-8px"
                                    onClick={onGoBack}
                                >
                                    <img src={arrowLeftIcon} alt="Back" />
                                </div>

                                <div className="flex-1">
                                    <PrimaryText
                                        typography="h3"
                                        className="mouseflow-ignore"
                                    >
                                        {UtilHelper.capitalizeWordsInSentence(
                                            [
                                                selectedItem.coverageResult
                                                    .demographics?.firstName ||
                                                    selectedItem.requestData
                                                        .firstName ||
                                                    "John",
                                                selectedItem.coverageResult
                                                    .demographics?.lastName ||
                                                    selectedItem.requestData
                                                        .lastName ||
                                                    "Doe",
                                            ].join(" ")
                                        )}
                                    </PrimaryText>

                                    <CoverageResultFlagsComponent
                                        flags={
                                            selectedItem.coverageResult.flags
                                        }
                                    />
                                </div>
                            </div>

                            <div
                                className="
                                    flex items-start
                                    md:mt-24px
                                    sm:block
                                "
                            >
                                {hasRunChecksPermission &&
                                selectedPracticeRole?.monthlyCoverageQuotaLeft &&
                                !isOverrideModeActive ? (
                                    <div className="mr-16px sm:mr-0px">
                                        <ButtonElement
                                            buttonClassName="sm:w-full"
                                            htmlType="button"
                                            type="default"
                                            label="Run Check"
                                            icon={refreshIcon}
                                            isRightIcon={true}
                                            disabled={
                                                !isRetryCheckAllowedByStatusCode
                                            }
                                            isLoading={
                                                isRetryingCoverageCheckLoading
                                            }
                                            onClick={retryCoverageCheck}
                                        />

                                        {!isRetryCheckAllowedByStatusCode && (
                                            <TooltipElement
                                                inlineLabel={
                                                    TOOLTIPS_CONFIG
                                                        .singlePolicyView
                                                        .cantReRunCheckText
                                                        .title
                                                }
                                                inlineLabelTypography="captionSemibold"
                                                isInlined
                                                text={TOOLTIPS_CONFIG.singlePolicyView.cantReRunCheckText.text(
                                                    CoverageCheckerMode.MANUAL
                                                )}
                                                backgroundColor={
                                                    NIRVANA_COLORS.brand.black
                                                }
                                            />
                                        )}
                                    </div>
                                ) : null}

                                {canOverridePolicyCoverage ? (
                                    <>
                                        {selectedItemIdentificationData &&
                                        !isOverrideModeActive ? (
                                            <ButtonElement
                                                className="sm:mt-8px"
                                                buttonClassName="sm:w-full"
                                                htmlType="button"
                                                type="default"
                                                label={
                                                    overridesState.selectedPolicyOverride
                                                        ? "Edit Override"
                                                        : "Override"
                                                }
                                                icon={editIcon}
                                                isRightIcon={true}
                                                disabled={
                                                    isOverrideActionsDisabled ||
                                                    isRetryingCoverageCheckLoading
                                                }
                                                onClick={() =>
                                                    setIsOverrideModeActive(
                                                        true
                                                    )
                                                }
                                            />
                                        ) : selectedItemIdentificationData &&
                                          isOverrideModeActive ? (
                                            <div
                                                className="flex items-center sm:block"
                                                key={overridesFormKey}
                                            >
                                                <PrimaryText
                                                    typography="textSmall"
                                                    className="mr-8px min-w-0 flex-shrink-0"
                                                >
                                                    Overrides Expiration Date
                                                </PrimaryText>

                                                <div className="relative mr-16px md:max-w-200px sm:mr-0px">
                                                    <DatePickerElement
                                                        name="expirationDate"
                                                        size="small"
                                                        defaultValue={
                                                            overridesDataWatcher.expirationDate
                                                        }
                                                        reactHookFormValidations={{
                                                            required:
                                                                VALIDATION_CONFIG.required,
                                                        }}
                                                        shouldValidate
                                                        reactHookFormErrors={
                                                            overridesFormMethods
                                                                .formState
                                                                .errors
                                                        }
                                                        reactHookFormRegister={
                                                            overridesFormMethods.register
                                                        }
                                                        reactHookFormUnregister={
                                                            overridesFormMethods.unregister
                                                        }
                                                        reactHookFormSet={
                                                            overridesFormMethods.setValue
                                                        }
                                                        minDate={minOverrideExpirationDate.toDate()}
                                                        disabled={
                                                            isCreateOverrideLoading
                                                        }
                                                    />

                                                    {!overridesDataWatcher.expirationDate ? (
                                                        <PrimaryText
                                                            typography="caption"
                                                            className="absolute bottom--20px whitespace-nowrap"
                                                        >
                                                            Suggested:{" "}
                                                            <span
                                                                className="cursor-pointer text-brand-vibrantPurple underline text-bold"
                                                                onClick={
                                                                    onUseSuggestedOverrideExpiration
                                                                }
                                                            >
                                                                {suggestedOverrideExpirationDate.format(
                                                                    GENERAL_CONFIG.defaultMomentDateFormat
                                                                )}
                                                            </span>
                                                        </PrimaryText>
                                                    ) : null}
                                                </div>

                                                <div className="flex items-center sm:mt-40px sm:block">
                                                    <ButtonElement
                                                        buttonClassName="w-100px sm:w-full"
                                                        htmlType="button"
                                                        type="default"
                                                        label="Reset"
                                                        disabled={
                                                            isCreateOverrideLoading
                                                        }
                                                        onClick={() => {
                                                            onResetOverride()
                                                        }}
                                                    />

                                                    <ButtonElement
                                                        className="ml-16px sm:ml-0px sm:mt-8px"
                                                        buttonClassName="w-100px sm:w-full"
                                                        htmlType="button"
                                                        type="default"
                                                        isDanger
                                                        label="Cancel"
                                                        disabled={
                                                            isCreateOverrideLoading
                                                        }
                                                        onClick={() => {
                                                            setIsOverrideModeActive(
                                                                false
                                                            )
                                                        }}
                                                    />

                                                    <ButtonElement
                                                        className="ml-16px sm:ml-0px sm:mt-8px"
                                                        buttonClassName="w-100px sm:w-full"
                                                        htmlType="button"
                                                        type="primary"
                                                        label="Save"
                                                        disabled={
                                                            isFormSubmitDisabled
                                                        }
                                                        isLoading={
                                                            isCreateOverrideLoading
                                                        }
                                                        onClick={overridesFormMethods.handleSubmit(
                                                            onSaveOverride
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}

                                        {overridesState.selectedPolicyOverride &&
                                        !isOverrideModeActive ? (
                                            <ButtonElement
                                                className="ml-16px sm:mt-8px sm:ml-0px"
                                                buttonClassName="sm:w-full"
                                                htmlType="button"
                                                type="default"
                                                isDanger
                                                label="Remove Override"
                                                isLoading={
                                                    isDeleteOverrideLoading
                                                }
                                                disabled={
                                                    isOverrideActionsDisabled ||
                                                    isRetryingCoverageCheckLoading
                                                }
                                                onClick={onDeleteOverride}
                                            />
                                        ) : null}
                                    </>
                                ) : null}

                                {similarPoliciesUrl && !isOverrideModeActive ? (
                                    <div className="ml-16px sm:ml-0px sm:mt-8px">
                                        <a
                                            href={similarPoliciesUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <ButtonElement
                                                htmlType="button"
                                                type="default"
                                                label="Find Similar Policies"
                                                buttonClassName="sm:w-full"
                                                disabled={
                                                    isRetryingCoverageCheckLoading
                                                }
                                            />
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div
                            className="flex items-start md:block"
                            style={{
                                height: !isTablet
                                    ? `calc(100vh - ${
                                          GENERAL_CONFIG.headerHeight +
                                          77 +
                                          (selectedItem.coverageResult.flags
                                              .length
                                              ? 30
                                              : 0)
                                      }px)`
                                    : undefined,
                            }}
                        >
                            {props.isFlagResolution ? (
                                <FlagsResolutionComponent
                                    className="w-300px md:w-full self-stretch"
                                    coverageCheck={selectedItem}
                                />
                            ) : null}

                            <div className="flex-1 h-full overflow-y-auto self-stretch pb-48px">
                                <CoverageResultGeneralComponent
                                    npi={
                                        selectedItem.requestData.providerNpi ||
                                        selectedItem.requestedHealthProvider
                                            ?.npi
                                    }
                                    providerName={
                                        selectedItem.requestedHealthProvider
                                            ?.name
                                    }
                                    cptCode={
                                        selectedItem.requestData.cptCode ||
                                        COVERAGE_CONFIG.defaultHealthProviderCptCode
                                    }
                                    sessionRate={
                                        selectedItem.requestData
                                            .sessionCharge ||
                                        selectedItem.coverageResult
                                            .currentSessionRate ||
                                        undefined
                                    }
                                    customerPatientType={
                                        selectedItem.coverageResult
                                            .customerPatientType
                                    }
                                    customerPatientId={
                                        selectedItem.coverageResult
                                            .customerPatientId
                                    }
                                    customerPatientNextAppointmentDate={
                                        selectedItem.coverageResult
                                            .customerPatientNextAppointmentDate
                                    }
                                    memberFirstName={
                                        selectedItem.requestData.firstName
                                    }
                                    memberFirstNameOverride={
                                        selectedItem.coverageResult.demographics
                                            ?.firstName
                                    }
                                    memberLastName={
                                        selectedItem.requestData.lastName
                                    }
                                    memberLastNameOverride={
                                        selectedItem.coverageResult.demographics
                                            ?.lastName
                                    }
                                    memberDob={selectedItem.requestData.dob}
                                    memberDobOverride={
                                        selectedItem.coverageResult.demographics
                                            ?.dob
                                    }
                                    memberGender={
                                        selectedItem.coverageResult.demographics
                                            ?.gender
                                    }
                                    memberAddress={
                                        selectedItem.coverageResult.demographics
                                            ?.address || undefined
                                    }
                                    relationshipToSubscriber={
                                        selectedItem.coverageResult
                                            .relationshipToSubscriber
                                    }
                                    subscriberDemographics={
                                        selectedItem.coverageResult
                                            .subscriberDemographics
                                    }
                                />

                                <CoverageResultBreakdownWrapperComponent
                                    coverageResult={selectedItem.coverageResult}
                                    coverageOverride={
                                        isOverridesEnabled
                                            ? overridesState.selectedPolicyOverride ||
                                              undefined
                                            : undefined
                                    }
                                    requestData={selectedItem.requestData}
                                    onSmartScanSuccess={onSmartScanSuccess}
                                    requestPayer={selectedItem.requestPayer}
                                    responsePayer={selectedItem.responsePayer}
                                    policy={selectedItem.policy}
                                    requestedHealthProvider={
                                        selectedItem.requestedHealthProvider
                                    }
                                    ignoreReporting={true}
                                    isOverridesModeActivated={
                                        isOverrideModeActive
                                    }
                                    overridesFormKey={overridesFormKey}
                                    isOverridesFormDisabled={
                                        isCreateOverrideLoading
                                    }
                                />
                            </div>

                            {(selectedItemIdentificationData ||
                                selectedItemPatientIdentificationData) &&
                            !isOverrideModeActive ? (
                                <PolicyCommentsComponent
                                    className="self-stretch"
                                    patient={
                                        selectedItemPatientIdentificationData ||
                                        undefined
                                    }
                                    policy={
                                        selectedItemIdentificationData ||
                                        undefined
                                    }
                                />
                            ) : null}
                        </div>
                    </FormProvider>
                </>
            ) : (
                <div className="text-center mt-32px">
                    <SpinnerElement />
                </div>
            )}
        </div>
    )
}
