/* eslint-disable react-hooks/exhaustive-deps */
import { PayerCoverageCheckNetwork } from "../config/coverage.config"
import { useMemo } from "react"
import { ISelectRenderedOption, UtilHelper } from "nirvana-react-elements"
import { FieldValues, UseFormSetValue, UseFormWatch } from "react-hook-form"

export const useCoveragePayerSelection = (
    coveragePayers: ICoveragePayer[],
    cptCodes: ISelectRenderedOption[],
    reactHookFormSet: UseFormSetValue<FieldValues>,
    reactHookFormWatch: UseFormWatch<FieldValues>,
    setNetworkFields = true,
    fieldGroupName?: string
) => {
    const dataWatcher = reactHookFormWatch()

    const fieldNames = useMemo(() => {
        const inNetwork = fieldGroupName
            ? `${fieldGroupName}.inNetworkCheck`
            : "inNetworkCheck"

        const outNetwork = fieldGroupName
            ? `${fieldGroupName}.outNetworkCheck`
            : "outNetworkCheck"

        const sessionRate = fieldGroupName
            ? `${fieldGroupName}.sessionRate`
            : "sessionRate"

        const payer = fieldGroupName ? `${fieldGroupName}.payer` : "payer"
        const cptCode = fieldGroupName ? `${fieldGroupName}.cptCode` : "cptCode"

        return {
            inNetwork,
            outNetwork,
            sessionRate,
            payer,
            cptCode,
        }
    }, [fieldGroupName])

    const getMatchingCoveragePayer = (
        payer?: IPayer,
        preferredNetwork = PayerCoverageCheckNetwork.IN
    ): ICoveragePayer | undefined => {
        return (
            coveragePayers.find(
                item =>
                    item.payer.payerId === payer?.payerId &&
                    item.defaultCoverageCheckNetwork === preferredNetwork
            ) ||
            coveragePayers.find(item => item.payer.payerId === payer?.payerId)
        )
    }

    const availableCptCodeOptions = useMemo<ISelectRenderedOption[]>(() => {
        const preSavedPayerCodes = getMatchingCoveragePayer(
            dataWatcher.payer
        )?.rates.map(item => item.code)

        // First return preSaved payer values and then all other options
        return [
            ...cptCodes.filter(item =>
                preSavedPayerCodes?.includes(item.value)
            ),

            ...cptCodes.filter(
                item => !preSavedPayerCodes?.includes(item.value)
            ),
        ]
    }, [dataWatcher.payer])

    const onPayerSelected = (payer: IPayer) => {
        const coveragePayer = getMatchingCoveragePayer(payer)

        if (!coveragePayer) {
            return
        }

        if (setNetworkFields) {
            switch (coveragePayer.defaultCoverageCheckNetwork) {
                case PayerCoverageCheckNetwork.IN:
                    reactHookFormSet(fieldNames.inNetwork, true)
                    reactHookFormSet(fieldNames.outNetwork, false)

                    break

                case PayerCoverageCheckNetwork.OUT:
                    reactHookFormSet(fieldNames.inNetwork, false)
                    reactHookFormSet(fieldNames.outNetwork, true)

                    break

                case PayerCoverageCheckNetwork.IN_OUT:
                    reactHookFormSet(fieldNames.inNetwork, true)
                    reactHookFormSet(fieldNames.outNetwork, true)

                    break
            }
        }

        setTimeout(() => {
            onCoveragePayerSelected(coveragePayer)
        })
    }

    const onCoveragePayerSelected = (coveragePayer: ICoveragePayer) => {
        reactHookFormSet(fieldNames.payer, coveragePayer.payer)

        if (!coveragePayer.rates.length) {
            return
        }

        onPayerRateSelected(coveragePayer.rates[0].code)
    }

    const onPayerRateSelected = (cptCode: string) => {
        // Set CPT code value
        reactHookFormSet(fieldNames.cptCode, cptCode, {
            shouldValidate: true,
        })

        const currentPayer = reactHookFormWatch(fieldNames.payer) as IPayer
        const currentCoveragePayer = getMatchingCoveragePayer(currentPayer)

        const neededPayerRate = currentCoveragePayer?.rates.find(
            item => item.code === cptCode
        )

        // If CPT code has pre-saved value for sessionRate -> set is as well
        // Amount comes in cents here -> so convert it to dollars
        neededPayerRate &&
            reactHookFormSet(
                fieldNames.sessionRate,
                UtilHelper.formatCents(neededPayerRate.amount).toString(),
                {
                    shouldValidate: true,
                }
            )
    }

    return {
        availableCptCodeOptions,
        onPayerSelected,
        onPayerRateSelected,
    }
}
