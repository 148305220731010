import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import {
    CoverageCheckerManualCheckType,
    CoverageCheckerMode,
    CoverageCheckerRunningState,
} from "../../config/checker.config"
import { PayerCoverageCheckNetwork } from "../../config/coverage.config"

const initialState: ICheckerState = {
    checkerMode: CoverageCheckerMode.MANUAL,
    manualCheckType: CoverageCheckerManualCheckType.CONTINUOUS_MONITORING,
    runningState: CoverageCheckerRunningState.INPUT_MANUAL,
    coverageChecksSubmitted: false,
    inputDataSetAt: new Date().getTime(),
    continuousMonitoringCoverageChecks: [],
    intakeCheck: {
        inputData: null,
        result: {},
    },
}

const checkerSlice = createSlice({
    name: "checker",
    initialState,
    reducers: {
        checkerReset: state => {
            return {
                ...initialState,

                // Preserve is csv running type during reset
                checkerMode: state.checkerMode,
                manualCheckType: state.manualCheckType,
                runningState:
                    state.checkerMode === CoverageCheckerMode.CSV
                        ? CoverageCheckerRunningState.INPUT_CSV
                        : CoverageCheckerRunningState.INPUT_MANUAL,

                // Preserve intakeCheck input data
                intakeCheck: {
                    inputData: state.intakeCheck.inputData,
                    result: {},
                },
            }
        },

        checkerToggleRunningType: (
            state,
            action: PayloadAction<{ checkerMode?: CoverageCheckerMode }>
        ) => {
            // Toggle is csv running type + reset
            // Can accept specific value or will toggle

            const newCheckerMode =
                typeof action.payload.checkerMode !== "undefined"
                    ? action.payload.checkerMode
                    : state.checkerMode === CoverageCheckerMode.CSV
                    ? CoverageCheckerMode.MANUAL
                    : CoverageCheckerMode.CSV

            return {
                ...initialState,

                checkerMode: newCheckerMode,
                manualCheckType: state.manualCheckType,
                runningState:
                    newCheckerMode === CoverageCheckerMode.CSV
                        ? CoverageCheckerRunningState.INPUT_CSV
                        : CoverageCheckerRunningState.INPUT_MANUAL,

                // Preserve intakeCheck input data
                intakeCheck: {
                    inputData: state.intakeCheck.inputData,
                    result: {},
                },
            }
        },

        checkerSetManualCheckType: (
            state,
            action: PayloadAction<CoverageCheckerManualCheckType>
        ) => {
            state.manualCheckType = action.payload
        },

        checkerSetRunningState: (
            state,
            action: PayloadAction<CoverageCheckerRunningState>
        ) => {
            state.runningState = action.payload
        },

        checkerSetCoverageChecksSubmitted: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.coverageChecksSubmitted = action.payload
        },

        checkerSetContinuousMonitoringInputData: (
            state,
            action: PayloadAction<IContinuousMonitoringCoverageCheckInputData[]>
        ) => {
            state.inputDataSetAt = new Date().getTime()
            state.continuousMonitoringCoverageChecks = action.payload.map(
                inputData => ({
                    inputData,
                })
            )
        },

        checkerRemoveContinuousMonitoringInputData: (
            state,
            action: PayloadAction<IContinuousMonitoringCoverageCheckInputData>
        ) => {
            state.inputDataSetAt = new Date().getTime()
            state.continuousMonitoringCoverageChecks =
                state.continuousMonitoringCoverageChecks.filter(
                    item => item.inputData.id !== action.payload.id
                )
        },

        checkerReceivedContinuousMonitoringCoverageResult: (
            state,
            action: PayloadAction<ICoverageResult>
        ) => {
            const newCoverageChecks = [
                ...state.continuousMonitoringCoverageChecks,
            ]

            // Use input data id that we've generated previously -> find needed index by it
            const neededCheckIndex = newCoverageChecks.findIndex(
                check => check.inputData.id === action.payload.resultId
            )

            if (~neededCheckIndex) {
                newCoverageChecks[neededCheckIndex].result = {
                    ...(newCoverageChecks[neededCheckIndex].result || {}),

                    [action.payload.isInNetworkCheck
                        ? PayerCoverageCheckNetwork.IN
                        : PayerCoverageCheckNetwork.OUT]: action.payload,
                }
            }

            state.continuousMonitoringCoverageChecks = newCoverageChecks
        },

        checkerSetIntakeInputData: (
            state,
            action: PayloadAction<IIntakeCheckerInputData>
        ) => {
            state.inputDataSetAt = new Date().getTime()
            state.intakeCheck.inputData = action.payload
        },

        checkerReceivedIntakeCoverageResult: (
            state,
            action: PayloadAction<ICommonCheckResult>
        ) => {
            state.intakeCheck.result = action.payload
        },
    },
})

export const {
    checkerReset,
    checkerToggleRunningType,
    checkerSetRunningState,
    checkerSetManualCheckType,
    checkerSetContinuousMonitoringInputData,
    checkerRemoveContinuousMonitoringInputData,
    checkerSetCoverageChecksSubmitted,
    checkerReceivedContinuousMonitoringCoverageResult,
    checkerSetIntakeInputData,
    checkerReceivedIntakeCoverageResult,
} = checkerSlice.actions

export const checkerReducer = checkerSlice.reducer
