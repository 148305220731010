import React, { useMemo } from "react"

import { CHECKER_CONFIG } from "../../config/checker.config"
import { HtmlHelper } from "../../helpers/html.helper"

export const NetworkBadgeElement: React.FunctionComponent<{
    coverageResult: ICoverageResult
}> = props => {
    const isActivePlanStatus = useMemo<boolean>(() => {
        return (
            !!props.coverageResult.planStatus &&
            CHECKER_CONFIG.activePlanStatuses.includes(
                props.coverageResult.planStatus
            )
        )
    }, [props.coverageResult])

    return HtmlHelper.getHtmlWrappedBadge(
        props.coverageResult.isInNetworkCheck ? "INN Benefits" : "OON Benefits",
        undefined,
        undefined,
        isActivePlanStatus
    )
}
