/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import moment from "moment-timezone"
import {
    ButtonElement,
    PrimaryText,
    IStepperStepProps,
    InputElement,
    VALIDATION_CONFIG,
    DatePickerElement,
    AutoCompleteElement,
    LookupHelper,
    useCptCodes,
    AvailableLookupConsumer,
    SelectElement,
} from "nirvana-react-elements"

import {
    COVERAGE_CONFIG,
    CoveragePatientType,
} from "../../../../config/coverage.config"
import { useAppSelector } from "../../../../store/selectors/app.selector"
import { TOOLTIPS_CONFIG } from "../../../../config/tooltips.config"
import { GENERAL_CONFIG } from "../../../../config/general.config"
import { checkerSelector } from "../../../../store/selectors/checker.selector"
import { LookupService } from "../../../../services/lookup.service"
import { coveragePayersSelector } from "../../../../store/selectors/coveragePayers.selector"
import { useCoveragePayerSelection } from "../../../../hooks/coveragePayerSelection.hooks"
import { selectedPracticeRoleSelector } from "../../../../store/selectors/selectedPracticeRole.selector"

export const PatientInformationStep: React.FunctionComponent<
    IStepperStepProps
> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch,
        register,
        unregister,
    } = useForm()

    const coveragePayers = useAppSelector(coveragePayersSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const {
        intakeCheck: { inputData: stateInputData },
    } = useAppSelector(checkerSelector)

    const { availableCptCodes } = useCptCodes(
        AvailableLookupConsumer.coveragePortal,
        selectedPracticeRole?.availableModalities
    )

    const { availableCptCodeOptions, onPayerSelected, onPayerRateSelected } =
        useCoveragePayerSelection(
            coveragePayers,
            availableCptCodes,
            setValue,
            watch,
            false
        )

    const [maxDob] = useState<Date>(new Date())
    const dataWatcher = watch()

    const formDefaultValues = useMemo<
        Partial<IIntakeCheckerPatientInformationInputData>
    >(() => {
        const stepperDefaultValues = props.defaultValues as
            | IIntakeCheckerPatientInformationInputData
            | undefined

        return {
            firstName:
                stepperDefaultValues?.firstName ||
                stateInputData?.patientInformation?.firstName,
            lastName:
                stepperDefaultValues?.lastName ||
                stateInputData?.patientInformation?.lastName,
            dob:
                stepperDefaultValues?.dob ||
                stateInputData?.patientInformation?.dob,
            payer:
                stepperDefaultValues?.payer ||
                stateInputData?.patientInformation?.payer,
            sessionRate:
                stepperDefaultValues?.sessionRate ||
                stateInputData?.patientInformation?.sessionRate,
            cptCode:
                stepperDefaultValues?.cptCode ||
                stateInputData?.patientInformation?.cptCode,
        }
    }, [props.defaultValues, stateInputData])

    // Set default values in form - objects should be set in useEffect
    useEffect(() => {
        if (formDefaultValues.payer) {
            setValue("payer", formDefaultValues.payer, {
                shouldValidate: true,
            })
        }

        if (formDefaultValues.cptCode) {
            setValue("cptCode", formDefaultValues.cptCode, {
                shouldValidate: true,
            })
        }
    }, [formDefaultValues])

    const onSubmit = (data: any) => {
        props.onStepSubmit(data as IIntakeCheckerPatientInformationInputData)
    }

    return (
        <div className="relative">
            <PrimaryText size={20} lineHeight={24}>
                Enter the patient’s basic information to get started
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <div
                    className="
                        flex mt-44px
                        md:block
                    "
                >
                    <InputElement
                        className="
                            mr-24px flex-1
                            md:mr-0px
                        "
                        label="Member First Name"
                        name="firstName"
                        tooltip={TOOLTIPS_CONFIG.calculate.firstName}
                        reactHookControl={control}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        reactHookErrors={errors}
                        defaultValue={formDefaultValues.firstName}
                        isLabelStatic
                    />

                    <InputElement
                        className="
                            flex-1
                            md:mt-44px
                        "
                        label="Member Last Name"
                        name="lastName"
                        tooltip={TOOLTIPS_CONFIG.calculate.lastName}
                        reactHookControl={control}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        reactHookErrors={errors}
                        defaultValue={formDefaultValues.lastName}
                        isLabelStatic
                    />
                </div>

                <div
                    className="
                        flex mt-44px
                        md:block
                    "
                >
                    <DatePickerElement
                        className="
                            mr-24px flex-1
                            md:mr-0px
                        "
                        name="dob"
                        label="Date of Birth"
                        defaultValue={
                            formDefaultValues.dob
                                ? moment(formDefaultValues.dob)
                                : undefined
                        }
                        reactHookFormValidations={{
                            required: VALIDATION_CONFIG.required,
                        }}
                        shouldValidate
                        reactHookFormErrors={errors}
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        maxDate={maxDob}
                        isLabelStatic
                    />

                    <div
                        className="
                            flex-1
                            md:mt-44px
                        "
                    >
                        <AutoCompleteElement
                            name="payer"
                            label="Payer"
                            tooltip={TOOLTIPS_CONFIG.calculate.payer}
                            placeholder=""
                            dataFetcher={(search?: string) =>
                                LookupService.lookupPayers(search)
                            }
                            itemRenderer={LookupHelper.getRenderedPayer}
                            reactHookFormErrors={errors}
                            reactHookFormRegister={register}
                            reactHookFormUnregister={unregister}
                            reactHookFormSet={setValue}
                            debounceMilliseconds={250}
                            defaultValue={
                                formDefaultValues.payer
                                    ? LookupHelper.getRenderedPayer(
                                          formDefaultValues.payer
                                      ).displayValue
                                    : undefined
                            }
                            validations={{
                                required: VALIDATION_CONFIG.required,
                            }}
                            shouldValidate
                            onSelected={onPayerSelected}
                            notFoundTitle="Don’t see your payer?"
                            isLabelStatic
                        />

                        <a
                            href={GENERAL_CONFIG.supportedInsurersUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="no-underline!"
                        >
                            <PrimaryText
                                className="mt-8px"
                                typography="caption"
                            >
                                View all supported payers
                            </PrimaryText>
                        </a>
                    </div>
                </div>

                <div
                    className="
                        flex mt-44px
                        md:block
                    "
                >
                    <InputElement
                        className="
                            mr-24px flex-1
                            md:mr-0px
                        "
                        type="number"
                        label="Session Rate"
                        name="sessionRate"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            min: VALIDATION_CONFIG.minAmount,
                        }}
                        defaultValue={formDefaultValues.sessionRate}
                        inputPrefix={<>$</>}
                        isLabelStatic
                    />

                    <SelectElement
                        className="
                            mr-24px flex-1
                            md:mr-0px md:mt-44px
                        "
                        label="Patient Type"
                        name="customerPatientType"
                        renderedOptions={Object.values(
                            COVERAGE_CONFIG.selectRenderedPatientTypes
                        )}
                        defaultValue={
                            COVERAGE_CONFIG.selectRenderedPatientTypes[
                                CoveragePatientType.NEW_PATIENT
                            ].displayValue
                        }
                        disabled
                    />

                    <SelectElement
                        className="
                            flex-1
                            md:mt-44px
                        "
                        label="CPT Code"
                        placeholder="Select one"
                        name="cptCode"
                        externalValue={dataWatcher.cptCode}
                        reactHookFormControl={control}
                        validations={{
                            required: VALIDATION_CONFIG.required,
                        }}
                        renderedOptions={availableCptCodeOptions}
                        onSelected={cptCode => onPayerRateSelected(cptCode)}
                    />
                </div>

                <div className="mt-24px flex items-center">
                    <div className="flex-1" />

                    <ButtonElement
                        label="Continue"
                        type="primary"
                        htmlType="submit"
                        size="large"
                    />
                </div>
            </form>
        </div>
    )
}
