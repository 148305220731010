import React from "react"

import { CalculatorResultType } from "../../config/checker.config"
import { HtmlHelper } from "../../helpers/html.helper"
import { TOOLTIPS_CONFIG } from "../../config/tooltips.config"

export const PartialCoverageBadgeElement: React.FunctionComponent<{
    coverageResult: ICoverageResult
}> = props => {
    if (
        props.coverageResult.resultType !==
        CalculatorResultType.activePartialCoverage
    ) {
        return null
    }

    return HtmlHelper.getHtmlWrappedBadge(
        "Cost Estimates Not Supported",
        undefined,
        TOOLTIPS_CONFIG.coverageBreakdown.activePartialCoverage
    )
}
