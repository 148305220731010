/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"

import { useAppSelector } from "../../../../store/selectors/app.selector"
import { useAppDispatch } from "../../../../store/appDispatch.hook"
import { checkerSelector } from "../../../../store/selectors/checker.selector"
import { CoverageCheckerRunningState } from "../../../../config/checker.config"
import { PayerCoverageCheckNetwork } from "../../../../config/coverage.config"
import { checkerSetRunningState } from "../../../../store/slices/checker.slice"
import { CheckerService } from "../../../../services/checker.service"
import { CoverageResultComponent } from "../../result/coverageResult.component"
import { IntakeCheckerResultsOverviewComponent } from "./intakeCheckerResultsOverview.component"

export const IntakeCheckerResultsComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()
    const checkerState = useAppSelector(checkerSelector)

    const mappedIntakeInputData =
        useMemo<IContinuousMonitoringCoverageCheckInputData | null>(() => {
            const inputData = checkerState.intakeCheck.inputData

            const inNetworkResult =
                checkerState.intakeCheck.result?.[PayerCoverageCheckNetwork.IN]

            const outNetworkResult =
                checkerState.intakeCheck.result?.[PayerCoverageCheckNetwork.OUT]

            // Wrong data in state
            if (!inputData || !(inNetworkResult || outNetworkResult)) {
                dispatch(
                    checkerSetRunningState(
                        CoverageCheckerRunningState.INPUT_MANUAL
                    )
                )

                return null
            }

            return CheckerService.mapIntakeInputDataToContinuousMonitoringInputData(
                inputData,
                inNetworkResult?.resultId ||
                    outNetworkResult?.resultId ||
                    new Date().getTime().toString(),
                inNetworkResult?.payer ||
                    outNetworkResult?.payer ||
                    inputData.patientInformation.payer
            )
        }, [])

    return mappedIntakeInputData && checkerState.intakeCheck.result ? (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <IntakeCheckerResultsOverviewComponent className="mb-24px" />

            <CoverageResultComponent
                isDefaultCollapsed
                inputData={mappedIntakeInputData}
                results={checkerState.intakeCheck.result}
            />
        </div>
    ) : null
}
