/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import {
    DatePickerElement,
    InputElement,
    PrimaryText,
    SelectElement,
    TooltipElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"
import { cloneDeep } from "lodash-es"

import {
    AvailableModalityCoverageStatus,
    AvailablePlanStatus,
    AvailablePlanType,
    COVERAGE_CONFIG,
} from "../../../config/coverage.config"
import {
    POLICIES_COLUMNS_CONFIG,
    PolicyColumnKey,
} from "../../../config/policiesColumns.config"
import { LookupHelper } from "../../../helpers/lookup.helper"
import { CHECKER_CONFIG } from "../../../config/checker.config"
import { OVERRIDES_CONFIG } from "../../../config/overrides.config"
import { InlinedOverrideFormBenefitStructureComponent } from "./inlinedOverrideFormBenefitStructure.component"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { UtilHelper } from "../../../helpers/util.helper"

export const InlinedOverrideFormFieldComponent: React.FunctionComponent<
    IInlinedOverrideFormFieldComponentProps
> = props => {
    const {
        register,
        control,
        unregister,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext()

    const benefitStructure = watch("benefitStructure")

    const isOtherBenefitStructure = useMemo<boolean>(() => {
        return benefitStructure === OVERRIDES_CONFIG.defaultBenefitsStructure
    }, [benefitStructure])

    const isFieldAlreadyOverridden = (
        fieldDataKey: keyof ICoverageOverrideFormData,
        secondaryFieldDataKey?: keyof ICoverageOverrideFormData
    ): boolean => {
        const value = props.defaultData.override?.[fieldDataKey]

        const secondaryValue = secondaryFieldDataKey
            ? props.defaultData.override?.[secondaryFieldDataKey]
            : null

        const setValue =
            typeof value === "number"
                ? UtilHelper.getNumericValueIfSet(
                      value,
                      secondaryValue === null ||
                          typeof secondaryValue === "undefined"
                          ? secondaryValue
                          : +secondaryValue
                  )
                : value || secondaryValue

        return typeof setValue !== "undefined" && setValue !== null
    }

    const getFieldAlreadyOverriddenIndicator = (
        fieldDataKey: keyof ICoverageOverrideFormData,
        secondaryFieldDataKey?: keyof ICoverageOverrideFormData
    ): JSX.Element | null => {
        return isFieldAlreadyOverridden(fieldDataKey, secondaryFieldDataKey) ? (
            <div className="mt-6px ml-8px h-12px w-12px bg-brand-greenHighlight rounded-full" />
        ) : null
    }

    const getWrappedField = (
        fieldDataKey: keyof ICoverageOverrideFormData,
        renderedField: JSX.Element,
        secondaryFieldDataKey?: keyof ICoverageOverrideFormData,
        tooltip?: string
    ): JSX.Element => {
        return (
            <div className="flex items-start">
                {renderedField}

                {tooltip ? (
                    <TooltipElement className="mt-4px ml-8px" text={tooltip} />
                ) : null}

                {getFieldAlreadyOverriddenIndicator(
                    fieldDataKey,
                    secondaryFieldDataKey
                )}
            </div>
        )
    }

    const getRenderedField = (): JSX.Element | string | null => {
        let renderedField: JSX.Element | undefined

        let fieldDataKey: keyof ICoverageOverrideFormData | undefined
        let secondaryFieldDataKey: keyof ICoverageOverrideFormData | undefined

        let tooltip: string | undefined

        const hasMixedValues =
            props.fieldKey &&
            props.defaultData.bulkMultipleValues?.[props.fieldKey]?.length

        const mixedValuesPlaceholder = hasMixedValues
            ? "Mixed values"
            : undefined

        switch (props.fieldKey) {
            case PolicyColumnKey.COPAYMENT:
                fieldDataKey = "copayment"

                renderedField = (
                    <InputElement
                        className="w-136px"
                        name={fieldDataKey}
                        type="number"
                        size="small"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        inputPrefix="$"
                        reactHookValidations={{
                            min: VALIDATION_CONFIG.minAmount0,
                        }}
                        defaultValue={props.defaultData.form[fieldDataKey]}
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.COINSURANCE:
                fieldDataKey = "coinsurance"

                renderedField = (
                    <InputElement
                        className="w-136px"
                        name={fieldDataKey}
                        type="number"
                        size="small"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        inputSuffix="%"
                        reactHookValidations={{
                            min: VALIDATION_CONFIG.minPercentage,
                            max: VALIDATION_CONFIG.maxPercentage,
                        }}
                        defaultValue={props.defaultData.form[fieldDataKey]}
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.TOTAL_DEDUCTIBLE:
                fieldDataKey = "deductible"

                renderedField = (
                    <InputElement
                        className="w-136px"
                        name={fieldDataKey}
                        type="number"
                        size="small"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        inputPrefix="$"
                        reactHookValidations={{
                            min: VALIDATION_CONFIG.minAmount0,
                        }}
                        defaultValue={props.defaultData.form[fieldDataKey]}
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.REMAINING_DEDUCTIBLE:
                fieldDataKey = "remainingDeductible"

                renderedField = (
                    <InputElement
                        className="w-136px"
                        name={fieldDataKey}
                        type="number"
                        size="small"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        inputPrefix="$"
                        reactHookValidations={{
                            min: VALIDATION_CONFIG.minAmount0,
                        }}
                        defaultValue={props.defaultData.form[fieldDataKey]}
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.TOTAL_OOP_MAX:
                fieldDataKey = "oopMax"

                renderedField = (
                    <InputElement
                        className="w-136px"
                        name={fieldDataKey}
                        type="number"
                        size="small"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        inputPrefix="$"
                        reactHookValidations={{
                            min: VALIDATION_CONFIG.minAmount0,
                        }}
                        defaultValue={props.defaultData.form[fieldDataKey]}
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.REMAINING_OOP_MAX:
                fieldDataKey = "remainingOopMax"

                renderedField = (
                    <InputElement
                        className="w-136px"
                        name={fieldDataKey}
                        type="number"
                        size="small"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        inputPrefix="$"
                        reactHookValidations={{
                            min: VALIDATION_CONFIG.minAmount0,
                        }}
                        defaultValue={props.defaultData.form[fieldDataKey]}
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.BENEFIT_STRUCTURE:
                fieldDataKey = "benefitStructure"

                renderedField = (
                    <InlinedOverrideFormBenefitStructureComponent
                        className={props.className}
                        defaultBenefitStructure={
                            props.defaultData.form.benefitStructure
                        }
                        defaultCustomBenefitStructure={
                            props.defaultData.form.customBenefitStructure
                        }
                        isDisabled={props.isDisabled}
                        benefitStructureNotRequired={
                            props.benefitStructureNotRequired
                        }
                    />
                )

                break

            case PolicyColumnKey.PLAN_STATUS:
                fieldDataKey = "planStatus"

                renderedField = (
                    <SelectElement
                        className="w-136px"
                        name={fieldDataKey}
                        size="small"
                        renderedOptions={LookupHelper.getRenderedOptionsFromObject<AvailablePlanStatus>(
                            COVERAGE_CONFIG.availablePlanStatusMapping,
                            OVERRIDES_CONFIG.notAllowedPlanStatusValues
                        )}
                        allowClear
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        defaultValue={
                            props.defaultData.form.planStatus &&
                            !OVERRIDES_CONFIG.notAllowedPlanStatusValues.includes(
                                props.defaultData.form.planStatus
                            )
                                ? COVERAGE_CONFIG.availablePlanStatusMapping[
                                      props.defaultData.form.planStatus
                                  ]
                                : undefined
                        }
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.PLAN_TYPE:
                fieldDataKey = "planType"

                renderedField = (
                    <SelectElement
                        className="w-290px"
                        name={fieldDataKey}
                        size="small"
                        renderedOptions={LookupHelper.getRenderedOptionsFromObject<AvailablePlanType>(
                            CHECKER_CONFIG.planTypeMapping
                        )}
                        allowClear
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        defaultValue={
                            props.defaultData.form.planType
                                ? CHECKER_CONFIG.planTypeMapping[
                                      props.defaultData.form.planType
                                  ]
                                : undefined
                        }
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.PLAN_BEGIN_END_DATE:
                fieldDataKey = "planBeginDate"
                secondaryFieldDataKey = "planEndDate"

                renderedField = (
                    <div className="flex items-center">
                        <DatePickerElement
                            className="w-136px"
                            name={fieldDataKey}
                            reactHookFormErrors={errors}
                            shouldValidate
                            size="small"
                            reactHookFormRegister={register}
                            reactHookFormUnregister={unregister}
                            reactHookFormSet={setValue}
                            allowClear
                            defaultValue={
                                props.defaultData.form[fieldDataKey] ||
                                undefined
                            }
                            disabled={props.isDisabled}
                            placeholder={mixedValuesPlaceholder}
                        />

                        <PrimaryText className="mx-4px">-</PrimaryText>

                        <DatePickerElement
                            className="w-136px"
                            name={secondaryFieldDataKey}
                            reactHookFormErrors={errors}
                            shouldValidate
                            size="small"
                            reactHookFormRegister={register}
                            reactHookFormUnregister={unregister}
                            reactHookFormSet={setValue}
                            allowClear
                            defaultValue={
                                props.defaultData.form[secondaryFieldDataKey] ||
                                undefined
                            }
                            disabled={props.isDisabled}
                            placeholder={mixedValuesPlaceholder}
                        />
                    </div>
                )

                break

            case PolicyColumnKey.COVERAGE_STATUS:
                fieldDataKey = "modalityCoverageStatus"

                renderedField = (
                    <SelectElement
                        className="w-136px"
                        name={fieldDataKey}
                        size="small"
                        renderedOptions={LookupHelper.getRenderedOptionsFromObject<AvailableModalityCoverageStatus>(
                            COVERAGE_CONFIG.availableModalityCoverageStatusMapping,
                            OVERRIDES_CONFIG.notAllowedModalityCoverageStatusValues
                        )}
                        allowClear
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        defaultValue={
                            props.defaultData.form.modalityCoverageStatus &&
                            !OVERRIDES_CONFIG.notAllowedModalityCoverageStatusValues.includes(
                                props.defaultData.form.modalityCoverageStatus
                            )
                                ? COVERAGE_CONFIG
                                      .availableModalityCoverageStatusMapping[
                                      props.defaultData.form
                                          .modalityCoverageStatus
                                  ]
                                : undefined
                        }
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.ELIGIBILITY_BEGIN_END_DATE:
                fieldDataKey = "eligibilityBeginDate"
                secondaryFieldDataKey = "eligibilityEndDate"

                renderedField = (
                    <div className="flex items-center">
                        <DatePickerElement
                            className="w-136px"
                            name={fieldDataKey}
                            reactHookFormErrors={errors}
                            shouldValidate
                            size="small"
                            reactHookFormRegister={register}
                            reactHookFormUnregister={unregister}
                            reactHookFormSet={setValue}
                            allowClear
                            defaultValue={
                                props.defaultData.form[fieldDataKey] ||
                                undefined
                            }
                            disabled={props.isDisabled}
                            placeholder={mixedValuesPlaceholder}
                        />

                        <PrimaryText className="mx-4px">-</PrimaryText>

                        <DatePickerElement
                            className="w-136px"
                            name={secondaryFieldDataKey}
                            reactHookFormErrors={errors}
                            shouldValidate
                            size="small"
                            reactHookFormRegister={register}
                            reactHookFormUnregister={unregister}
                            reactHookFormSet={setValue}
                            allowClear
                            defaultValue={
                                props.defaultData.form[secondaryFieldDataKey] ||
                                undefined
                            }
                            disabled={props.isDisabled}
                            placeholder={mixedValuesPlaceholder}
                        />
                    </div>
                )

                break

            case PolicyColumnKey.THIRD_PARTY_PAYER:
                fieldDataKey = "thirdPartyPayer"

                renderedField = (
                    <InputElement
                        className="w-290px"
                        name={fieldDataKey}
                        size="small"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        defaultValue={props.defaultData.form[fieldDataKey]}
                        disabled={props.isDisabled}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.MEMBER_OBLIGATION:
                fieldDataKey = "memberObligation"

                tooltip = !isOtherBenefitStructure
                    ? OVERRIDES_CONFIG.tooltips.memberObligation
                    : undefined

                renderedField = (
                    <InputElement
                        className="w-136px"
                        name={fieldDataKey}
                        type="number"
                        size="small"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        inputPrefix="$"
                        reactHookValidations={{
                            min: VALIDATION_CONFIG.minAmount0,
                        }}
                        defaultValue={props.defaultData.form[fieldDataKey]}
                        disabled={props.isDisabled || !isOtherBenefitStructure}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break

            case PolicyColumnKey.PAYER_OBLIGATION:
                fieldDataKey = "payerObligation"

                tooltip = !isOtherBenefitStructure
                    ? OVERRIDES_CONFIG.tooltips.payerObligation
                    : undefined

                renderedField = (
                    <InputElement
                        className="w-136px"
                        name={fieldDataKey}
                        type="number"
                        size="small"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        inputPrefix="$"
                        reactHookValidations={{
                            min: VALIDATION_CONFIG.minAmount0,
                        }}
                        defaultValue={props.defaultData.form[fieldDataKey]}
                        disabled={props.isDisabled || !isOtherBenefitStructure}
                        placeholder={mixedValuesPlaceholder}
                    />
                )

                break
        }

        if (!renderedField || !fieldDataKey) {
            return props.fallback
        }

        if (!tooltip && props.fieldKey && hasMixedValues) {
            tooltip =
                "Mixed values: " +
                props.defaultData.bulkMultipleValues?.[props.fieldKey]
                    ?.map(value => {
                        const columnConfig = cloneDeep({
                            ...POLICIES_COLUMNS_CONFIG.columnsConfig[
                                props.fieldKey!
                            ],
                        })

                        if (columnConfig.formatting?.isCents) {
                            columnConfig.formatting.isCents = false
                        }

                        return PoliciesHelper.getFormattedColumnValue(
                            value,
                            columnConfig
                        )
                    })
                    .join(", ")
        }

        return getWrappedField(
            fieldDataKey,
            renderedField,
            secondaryFieldDataKey,
            tooltip
        )
    }

    return <div className={props.className}>{getRenderedField()}</div>
}
