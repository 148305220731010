import React, { useMemo } from "react"
import moment from "moment-timezone"

import { GENERAL_CONFIG } from "../../config/general.config"
import { CHECKER_CONFIG } from "../../config/checker.config"
import { HtmlHelper } from "../../helpers/html.helper"

export const LastCheckedBadgeElement: React.FunctionComponent<{
    coverageResult: ICoverageResult
}> = props => {
    const isActivePlanStatus = useMemo<boolean>(() => {
        return (
            !!props.coverageResult.planStatus &&
            CHECKER_CONFIG.activePlanStatuses.includes(
                props.coverageResult.planStatus
            )
        )
    }, [props.coverageResult])

    return HtmlHelper.getHtmlWrappedBadge(
        `Last Checked: ${moment(props.coverageResult.createdAt).format(
            GENERAL_CONFIG.defaultMomentDateFormat
        )}`,
        undefined,
        undefined,
        isActivePlanStatus
    )
}
