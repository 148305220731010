/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PageFloatingButton, PrimaryText } from "nirvana-react-elements"
import { useNavigate } from "react-router-dom"
import { ViewportList } from "react-viewport-list"

import {
    checkerRemoveContinuousMonitoringInputData,
    checkerSetRunningState,
} from "../../../store/slices/checker.slice"
import {
    CHECKER_CONFIG,
    CoverageCheckerRunningState,
} from "../../../config/checker.config"
import { UploadCsvComponent } from "./uploadCsv.component"
import { checkerRunContinuousMonitoringChecks } from "../../../store/thunks/checker.thunks"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { ScreenScrollableTableElement } from "../../../elements/screenScrollableTable.element"
import { SingleCsvCoverageCheckComponent } from "./singleCsvCoverageCheck.component"
import { ResultsQuickViewComponent } from "../quickView/resultsQuickView.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { checkerSelector } from "../../../store/selectors/checker.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { OrganizationQuoteComponent } from "../../general/quotaChecksProgressComponent.component"

import calculatorIcon from "../../../assets/images/icons/calculator-white.svg"

export const CoverageCheckerCSVComponent: React.FunctionComponent<
    ICoverageCheckerCSVComponentProps
> = props => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const checkerState = useAppSelector(checkerSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const resultsCount = useMemo<number>(() => {
        return (
            checkerState.continuousMonitoringCoverageChecks?.filter(
                item => !!item?.result
            ).length || 0
        )
    }, [checkerState.continuousMonitoringCoverageChecks])

    const tableHeaders = useMemo<JSX.Element>(() => {
        return (
            <div className="table-row w-full">
                <div
                    className="
                        flex items-center px-16px py-8px
                        bg-brand-white
                    "
                >
                    <div className="mr-16px flex-shrink-0 w-100px">
                        <PrimaryText
                            className="opacity-75 flex items-center"
                            typography="h6"
                        >
                            Row #
                        </PrimaryText>
                    </div>

                    {CHECKER_CONFIG.uploadCsvMapping.map(
                        (columnConfig, index) => {
                            return (
                                <div
                                    key={index}
                                    className="mr-16px flex-shrink-0 w-250px"
                                >
                                    <PrimaryText
                                        className="opacity-75 flex items-center"
                                        typography="h6"
                                    >
                                        {columnConfig.header}
                                    </PrimaryText>
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
        )
    }, [])

    const isValidInputRow = (
        row: IContinuousMonitoringCoverageCheckInputData
    ): boolean => {
        return !row.validationErrors?.length
    }

    const getValidInputStateRows = () => {
        return checkerState.continuousMonitoringCoverageChecks
            .map(item => item.inputData)
            .filter(isValidInputRow)
    }

    const validInputRowsCount = useMemo<number>(() => {
        return getValidInputStateRows().length
    }, [checkerState.inputDataSetAt])

    const performCalculation = () => {
        if (!selectedPracticeRole?.monthlyCoverageQuotaLeft) {
            return
        }

        dispatch(
            checkerRunContinuousMonitoringChecks({
                payload: getValidInputStateRows(),
                practice: selectedPracticeRole.practice,

                onSuccess: () => {
                    dispatch(
                        checkerSetRunningState(
                            CoverageCheckerRunningState.RESULTS_CSV
                        )
                    )
                },
            })
        )

        // Add random part to the url, so we can safely catch browser back button then
        // And if back button is clicked from showing results -> it will initiate "run new checks"
        // Acts as history.push
        navigate(
            `${ROUTES_CONFIG.coverageCheckerUrl}?${
                GENERAL_CONFIG.urlSearchParamsKeys.results
            }=${new Date().getTime()}`
        )
    }

    const onRemoveCoverageInputData = (
        coverageInputData: IContinuousMonitoringCoverageCheckInputData
    ) => {
        dispatch(checkerRemoveContinuousMonitoringInputData(coverageInputData))
    }

    return (
        <div
            className={`
                ${props.className}
                relative
            `}
        >
            {!checkerState.coverageChecksSubmitted ? (
                <UploadCsvComponent className="mt-24px" />
            ) : null}

            {resultsCount ? (
                <>
                    <ResultsQuickViewComponent className="mt-50px ml--45px mr--45px sm:ml--16px sm:mr--16px" />

                    {checkerState.continuousMonitoringCoverageChecks.length >
                    resultsCount ? (
                        <div
                            className="
                                mt-44px flex flex-col items-center justify-center px-16px py-24px
                                rounded-8px bg-brand-warmLight025
                            "
                        >
                            <PrimaryText typography="h5" centered>
                                Please note that it takes approximately 30
                                minutes to run 1000 checks. Please keep your tab
                                open until the checks are complete.
                            </PrimaryText>
                        </div>
                    ) : null}
                </>
            ) : checkerState.continuousMonitoringCoverageChecks.length ? (
                <>
                    <OrganizationQuoteComponent className="mt-32px" />

                    <ScreenScrollableTableElement
                        className="mt-32px"
                        headers={tableHeaders}
                    >
                        <ViewportList
                            items={
                                checkerState.continuousMonitoringCoverageChecks
                            }
                        >
                            {(item, index) => (
                                <SingleCsvCoverageCheckComponent
                                    key={index}
                                    rowIndex={index}
                                    coverageCheck={item}
                                    onInputDataRemove={
                                        onRemoveCoverageInputData
                                    }
                                />
                            )}
                        </ViewportList>
                    </ScreenScrollableTableElement>
                </>
            ) : null}

            {/*RUN COVERAGE CHECKS BTN*/}
            {/*SHOW when no results at all*/}
            {!resultsCount && validInputRowsCount && !props.isLoading ? (
                <PageFloatingButton
                    className="bottom-60px!"
                    zIndex={40}
                    label={`Run ${validInputRowsCount} Check${
                        validInputRowsCount > 1 ? "s" : ""
                    }`}
                    icon={calculatorIcon}
                    disabled={!selectedPracticeRole?.monthlyCoverageQuotaLeft}
                    onClick={performCalculation}
                />
            ) : null}
        </div>
    )
}
