/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"

import {
    COVERAGE_CONFIG,
    CoverageBreakdownSection,
} from "../../../config/coverage.config"
import {
    POLICIES_COLUMNS_CONFIG,
    PolicyColumnKey,
} from "../../../config/policiesColumns.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { CoverageResultBreakdownDetailsSectionComponent } from "./coverageResultBreakdownDetailsSection.component"
import { CoverageBreakdownBadgeElement } from "../../../elements/coverageBadges/coverageBreakdownBadge.element"
import { CHECKER_CONFIG } from "../../../config/checker.config"
import { CoverageResultFlagsComponent } from "../../policies/flags/coverageResultFlags.component"

export const CoverageResultBreakdownDetailsComponent: React.FunctionComponent<
    ICoverageResultBreakdownDetailsComponentProps
> = props => {
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    // Difference between demographics that was provided by user and that insurer has on file
    const demographicsDifference = useMemo<
        Partial<ICoverageResultDemographics>
    >(() => {
        return PoliciesHelper.getDemographicsDifference(
            props.requestData,
            props.coverageResult.demographics
        )
    }, [props.requestData, props.coverageResult])

    const generatedCoverageCheckHistory = useMemo<ICoverageCheckHistory>(() => {
        return {
            requestData: props.requestData,
            coverageResult: {
                ...props.coverageResult,

                // Use latest override that was fetched separately
                override:
                    props.coverageOverride || props.coverageResult.override,
            },
            policy: props.policy,
            responsePayer: props.responsePayer,
            requestPayer: props.requestPayer,
            requestedHealthProvider: props.requestedHealthProvider,
        }
    }, [
        props.requestData,
        props.coverageResult,
        props.coverageOverride,
        props.policy,
        props.responsePayer,
        props.requestPayer,
        props.requestedHealthProvider,
    ])

    const convertPolicyColumnToBreakdownData = (
        columnConfig: IExtendedPolicyColumnConfiguration
    ): ICoverageBreakdownData => {
        const value = PoliciesHelper.getMappedColumnValue(
            generatedCoverageCheckHistory,
            columnConfig,
            COVERAGE_CONFIG.defaultFallbackCoverageDataValueNA
        )

        return {
            label:
                columnConfig.coverageBreakdownLabelOverride ||
                columnConfig.label,
            value,
            fieldKey: columnConfig.columnKey,
            tooltip:
                value === COVERAGE_CONFIG.defaultFallbackCoverageDataValueNA
                    ? columnConfig.coverageBreakdownEmptyValueTooltip
                    : columnConfig.coverageBreakdownTooltip,
            isHighlighted: PoliciesHelper.isColumnCellHighlighted(
                generatedCoverageCheckHistory,
                columnConfig
            ),
        }
    }

    const getPlanStatusBreakdownData = (): ICoverageBreakdownData => {
        const data = convertPolicyColumnToBreakdownData(
            POLICIES_COLUMNS_CONFIG.columnsConfig.PLAN_STATUS
        )

        if (typeof data.value === "string") {
            data.value = (
                <CoverageBreakdownBadgeElement
                    label={data.value}
                    isActive={
                        !!props.coverageResult.planStatus &&
                        CHECKER_CONFIG.activePlanStatuses.includes(
                            props.coverageResult.planStatus
                        )
                    }
                    tooltip={data.tooltip}
                    labelTypography="captionSemibold"
                />
            )

            data.tooltip = undefined
        }

        return data
    }

    const getCoverageStatusBreakdownData = (): ICoverageBreakdownData => {
        const data = convertPolicyColumnToBreakdownData(
            POLICIES_COLUMNS_CONFIG.columnsConfig.COVERAGE_STATUS
        )

        if (typeof data.value === "string") {
            data.value = (
                <CoverageBreakdownBadgeElement
                    label={data.value}
                    isActive={
                        !!props.coverageResult.modalityCoverageStatusTernary
                    }
                    tooltip={data.tooltip}
                    labelTypography="captionSemibold"
                />
            )

            data.tooltip = undefined
        }

        return data
    }

    const sectionsData = useMemo<
        Record<CoverageBreakdownSection, ICoverageBreakdownData[]>
    >(() => {
        const result: Record<
            CoverageBreakdownSection,
            ICoverageBreakdownData[]
        > = {
            [CoverageBreakdownSection.FLAGS]: props.withFlagsSection
                ? [
                      {
                          value: (
                              <CoverageResultFlagsComponent
                                  flags={props.coverageResult.flags}
                              />
                          ),
                      },
                  ]
                : [],
            [CoverageBreakdownSection.POLICY_GENERAL]: [
                // Don't show payer value difference if in error mode and response payer is not defined
                {
                    label: "Payer",
                    value: props.requestPayer
                        ? `${props.requestPayer.insuranceName} / ${props.requestPayer.payerId}`
                        : COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
                    valueDifference: props.responsePayer
                        ? `${props.responsePayer.insuranceName} / ${props.responsePayer.payerId}`
                        : !props.isErrorModeFields
                        ? COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown
                        : undefined,
                    fieldKey: PolicyColumnKey.IDENTIFIED_PAYER,
                },
                {
                    label: "Member ID",
                    value:
                        props.requestData.memberId ||
                        props.coverageResult.demographics?.memberId ||
                        COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
                    valueDifference: demographicsDifference.memberId,
                    fieldKey: PolicyColumnKey.MEMBER_ID,
                },

                ...(!props.isErrorModeFields
                    ? [
                          getPlanStatusBreakdownData(),
                          getCoverageStatusBreakdownData(),
                      ]
                    : []),
            ],
            [CoverageBreakdownSection.POLICY_THIRD_PARTY]: [],
            [CoverageBreakdownSection.POLICY_ADDITIONAL_POLICY]: [],
            [CoverageBreakdownSection.POLICY_FINANCIALS]: [
                // Need this placeholder so deductible is pulled to the right
                // Since process for breaking in columns is automatic: it finds middle of array
                {
                    label: COVERAGE_CONFIG.breakdownPlaceholderLabel,
                    value: null,
                },
            ],
            [CoverageBreakdownSection.POLICY_OBLIGATION]: [],
            [CoverageBreakdownSection.SPECIALTY_SPECIFIC]: [],
        }

        const modalitySpecificColumns = selectedPracticeRole
            ?.availableModalities?.length
            ? PoliciesHelper.getModalitiesSpecificColumnsConfigurations(
                  selectedPracticeRole.availableModalities
              )
            : []

        Object.values(CoverageBreakdownSection).forEach(section => {
            let neededColumns = [
                // Remove modality specific columns definitions, they will be added below based on modality
                ...Object.values(POLICIES_COLUMNS_CONFIG.columnsConfig).filter(
                    item => !item.isModalitySpecific
                ),

                ...modalitySpecificColumns,
            ].filter(column => column.coverageBreakdownSection === section)

            if (props.isErrorModeFields) {
                neededColumns = neededColumns.filter(
                    item => item.showCoverageBreakdownErrorMode
                )
            }

            // Add common additional columns from common columns config
            for (const column of neededColumns) {
                const breakdownData = convertPolicyColumnToBreakdownData(column)
                const target = result[section]

                // If order provided - put at that order
                typeof column.coverageBreakdownOrder === "undefined"
                    ? target.push(breakdownData)
                    : target.splice(
                          column.coverageBreakdownOrder,
                          0,
                          breakdownData
                      )
            }
        })

        return result
    }, [
        props.coverageResult,
        props.requestData,
        props.coverageOverride,
        selectedPracticeRole?.availableModalities,
        generatedCoverageCheckHistory,
        demographicsDifference,
    ])

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <div
                key={props.overridesFormKey}
                className="grid grid-cols-2 gap-x-8 mt-16px"
            >
                {(Object.keys(sectionsData) as CoverageBreakdownSection[]).map(
                    (sectionKey, index) => {
                        if (
                            !sectionsData[sectionKey].filter(
                                item =>
                                    item.label !==
                                    COVERAGE_CONFIG.breakdownPlaceholderLabel
                            ).length
                        ) {
                            return null
                        }

                        const sectionConfig =
                            COVERAGE_CONFIG.coverageBreakdownSections[
                                sectionKey
                            ]

                        return (
                            <CoverageResultBreakdownDetailsSectionComponent
                                key={index}
                                className={`
                                    ${
                                        !sectionConfig.singleColumn
                                            ? "col-span-2"
                                            : "md:col-span-2"
                                    }
                                    ${sectionConfig.bigFont ? "mt-8px" : ""}
                                `}
                                sectionKey={sectionKey}
                                coverageCheckHistory={
                                    generatedCoverageCheckHistory
                                }
                                data={sectionsData[sectionKey]}
                                isOverridesModeActivated={
                                    props.isOverridesModeActivated
                                }
                                isOverridesFormDisabled={
                                    props.isOverridesFormDisabled
                                }
                            />
                        )
                    }
                )}
            </div>
        </div>
    )
}
