import {
    AvailablePlanStatus,
    AvailablePlanType,
    CoveragePatientType,
    Gender,
    PayerCoverageCheckNetwork,
} from "./coverage.config"
import { GENERAL_CONFIG } from "./general.config"

export enum CalculatorResultType {
    unknownPlanStatus = "unknownPlanStatus",
    unknownDemographics = "unknownDemographics",
    activeCoverage = "activeCoverage",
    activePartialCoverage = "activePartialCoverage",
    activePlanCoverageUnknown = "activePlanCoverageUnknown",
    noCoverage = "noCoverage",
    generalError = "generalError",
    memberNotFound = "memberNotFound",
    connectionIssue = "connectionIssue",
    payerNotSupported = "payerNotSupported",
    providerNotRecognized = "providerNotRecognized",
    errorRetrievingCoverage = "errorRetrievingCoverage",
    memberIdPayerIdInvalid = "memberIdPayerIdInvalid",
    planTypeNotSupported = "planTypeNotSupported",
    invalidInput = "invalidInput",
    unexpectedError = "unexpectedError",
    tooManyRequests = "tooManyRequests",
    payerFailure = "payerFailure",
}

export enum ExportDataSource {
    inputData = "inputData",
    outputData = "outputData",
    practiceRole = "practiceRole",
}

export enum CoverageCheckerMode {
    MANUAL = "manual",
    CSV = "csv",
}

export enum CoverageCheckerManualCheckType {
    CONTINUOUS_MONITORING = "continuousMonitoring",
    INTAKE = "intake",
}

export enum CoverageCheckerRunningState {
    INPUT_MANUAL = "INPUT_MANUAL",
    INPUT_CSV = "INPUT_CSV",
    PROCESS_CSV = "PROCESS_CSV",
    PREVIEW_CSV = "PREVIEW_CSV",
    RESULTS_MANUAL = "RESULTS_MANUAL",
    RESULTS_CSV = "RESULTS_CSV",
}

export const CHECKER_CONFIG = {
    maxGetEstimateRetry: 3,
    maxBulkCoverageChecks: 10, // 10 at a time checks

    maxBulkCoverageChecksCSV: 2 * 1000, // 2 000 at a time checks
    maxBulkCoverageChecksCSVExtended: 50 * 1000, // 50 000 at a time checks

    // How many checks we process at once
    processBatchSize: 10,
    processBatchSizeExtended: 25,

    bulkCoverageChecksTemplateUrl:
        "https://nirvana-assets.s3.amazonaws.com/nirvana-coverage-template-v2.3.csv",

    get checkerManualModeTabs(): ICheckerManualTypeTab[] {
        return [
            {
                key: CoverageCheckerManualCheckType.CONTINUOUS_MONITORING,
                label: "Continuous monitoring",
            },
            {
                key: CoverageCheckerManualCheckType.INTAKE,
                label: "Intake",
                isBeta: true,
            },
        ]
    },

    demographicsDifference: {
        inputRequiredKeys: [
            "memberId",
        ] as (keyof ICoverageResultDemographics)[],

        ignoreKeys: [
            "gender",
            "address",
        ] as (keyof ICoverageResultDemographics)[],
    },

    downloadFileName: "Nirvana Coverage Portal Results {{time}}.csv",

    activeCoverageResults: [
        CalculatorResultType.activeCoverage,
        CalculatorResultType.activePartialCoverage,
    ],

    activePlanStatuses: [
        AvailablePlanStatus.activeAtRisk,
        AvailablePlanStatus.active,
    ],

    get quickResultsSuccessfulResults(): string[] {
        return [
            ...CHECKER_CONFIG.activeCoverageResults,
            CalculatorResultType.noCoverage,
        ]
    },

    // Should come in order we expect it in csv
    uploadCsvHeaders: [
        "healthProviderNpi",
        "cptCode",
        "sessionCharge",
        "payerExternalId",
        "memberId",
        "dob",
        "firstName",
        "lastName",
        "network",
        "customerPatientType",
        "customerPatientId",
        "customerPatientNextAppointmentDate",
    ] as const,

    genderMapping: {
        [Gender.male]: "Male",
        [Gender.female]: "Female",
    },

    get planTypeMapping(): Record<AvailablePlanType, string> {
        return {
            [AvailablePlanType.ORIGINAL_MEDICAID]: "Original Medicaid",
            [AvailablePlanType.MANAGED_MEDICAID]: "Managed Medicaid",
            [AvailablePlanType.MEDICARE_ADVANTAGE]: "Medicare Advantage",
            [AvailablePlanType.ORIGINAL_MEDICARE]: "Original Medicare",
            [AvailablePlanType.UNKNOWN]: "Unknown",
        }
    },

    get calculatorResultTypeTitleMapping(): Record<
        CalculatorResultType,
        string
    > {
        return {
            [CalculatorResultType.activeCoverage]: "Active",
            [CalculatorResultType.activePartialCoverage]:
                "Active - Partial Data",

            [CalculatorResultType.noCoverage]: "Inactive",

            [CalculatorResultType.activePlanCoverageUnknown]:
                "Unknown Coverage Status",
            [CalculatorResultType.unknownPlanStatus]: "Unknown Plan Status",
            [CalculatorResultType.unknownDemographics]: "Unknown Demographics",
            [CalculatorResultType.generalError]: "API Error",
            [CalculatorResultType.memberNotFound]: "Member Not Found",
            [CalculatorResultType.connectionIssue]: "Failed Connection",
            [CalculatorResultType.payerNotSupported]: "Payer Not Supported",
            [CalculatorResultType.providerNotRecognized]:
                "Provider Not Recognized",
            [CalculatorResultType.errorRetrievingCoverage]:
                "Error Retrieving Coverage",
            [CalculatorResultType.memberIdPayerIdInvalid]:
                "Member ID With a Different Payer",
            [CalculatorResultType.planTypeNotSupported]:
                "Plan Type Not Supported",
            [CalculatorResultType.invalidInput]: "Invalid Data Format",
            [CalculatorResultType.unexpectedError]: "Unexpected Error",
            [CalculatorResultType.tooManyRequests]: "Too Many Requests",
            [CalculatorResultType.payerFailure]: "Payer Failure",
        }
    },

    get calculatorResultTypeColorMapping(): Record<
        CalculatorResultType,
        string
    > {
        return {
            [CalculatorResultType.activeCoverage]: "#7064EA",
            [CalculatorResultType.activePartialCoverage]: "#AE9BEA",

            [CalculatorResultType.noCoverage]: "#2F1D47",

            [CalculatorResultType.activePlanCoverageUnknown]: "#800000",
            [CalculatorResultType.unknownPlanStatus]: "#761919",
            [CalculatorResultType.unknownDemographics]: "#E0115F",
            [CalculatorResultType.generalError]: "#B22222",
            [CalculatorResultType.memberNotFound]: "#BC2626",
            [CalculatorResultType.connectionIssue]: "#A45A52",
            [CalculatorResultType.payerNotSupported]: "#EA3C53",
            [CalculatorResultType.providerNotRecognized]: "#D21F3C",
            [CalculatorResultType.errorRetrievingCoverage]: "#420D09",
            [CalculatorResultType.memberIdPayerIdInvalid]: "#5E1914",
            [CalculatorResultType.planTypeNotSupported]: "#8D021F",
            [CalculatorResultType.invalidInput]: "#FFA07A",
            [CalculatorResultType.unexpectedError]: "#E9967A",
            [CalculatorResultType.tooManyRequests]: "#DB7093",
            [CalculatorResultType.payerFailure]: "#BA1607",
        }
    },

    csvRunQuickViewExcludedResultTypes: [
        CalculatorResultType.activePlanCoverageUnknown,
    ] as CalculatorResultType[],

    uploadCsvMapping: [
        {
            header: "Provider NPI",
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "customNpi",
            },
            alternativeDataSource: {
                source: ExportDataSource.practiceRole,
                valuePath: "practice.groupNPI",
            },
        },
        {
            header: "CPT Code",
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "cptCode",
            },
        },
        {
            header: "Provider Session Cost",
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "sessionRate",
            },
            formatting: {
                currency: true,
            },
        },
        {
            header: `Payer Id`,
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "payer.payerId",
            },
        },
        {
            header: "Member Id",
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "memberId",
            },
        },
        {
            header: "Member DOB",
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "dob",
            },
            formatting: {
                dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
            },
        },
        {
            header: "Member First Name",
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "firstName",
            },
        },
        {
            header: "Member Last Name (optional)",
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "lastName",
            },
        },
        {
            header: `Network`,
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "inNetworkCheck",
            },
            formatting: {
                isNetworkTypeValue: true,
            },
        },
        {
            header: `Patient Type (optional)`,
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "customerPatientType",
            },
        },
        {
            header: `Patient ID (optional)`,
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "customerPatientId",
            },
        },
        {
            header: `Next Appointment Date (optional)`,
            dataSource: {
                source: ExportDataSource.inputData,
                valuePath: "customerPatientNextAppointmentDate",
            },
            formatting: {
                dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
            },
        },
    ] as ICsvFieldsMapping[],

    resultsCorrectedDataCsvMapping: [
        {
            header: `Corrected Payer ID (${GENERAL_CONFIG.supportedInsurersUrl})`,
            dataSource: {
                source: ExportDataSource.outputData,
                valuePath: "overriddenPayer.payerId",
            },
            alternativeDataSource: {
                source: ExportDataSource.outputData,
                valuePath: "payer.payerId",
            },
            dataCompareWith: {
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "payer.payerId",
                },
            },
        },
        {
            header: "Corrected Member First Name",
            dataSource: {
                source: ExportDataSource.outputData,
                valuePath: "demographics.firstName",
            },
            dataCompareWith: {
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "firstName",
                },
            },
        },
        {
            header: "Corrected Member Last Name",
            dataSource: {
                source: ExportDataSource.outputData,
                valuePath: "demographics.lastName",
            },
            dataCompareWith: {
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "lastName",
                },
            },
        },
        {
            header: "Corrected Member ID",
            dataSource: {
                source: ExportDataSource.outputData,
                valuePath: "demographics.memberId",
            },
            dataCompareWith: {
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "memberId",
                },
            },
        },
        {
            header: "Corrected Member DOB",
            dataSource: {
                source: ExportDataSource.outputData,
                valuePath: "demographics.dob",
            },
            dataCompareWith: {
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "dob",
                },
            },
        },
    ] as ICsvFieldsMapping[],

    get resultsCsvMapping(): ICsvFieldsMapping[] {
        return [
            {
                header: "Provider NPI",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customNpi",
                },
                alternativeDataSource: {
                    source: ExportDataSource.practiceRole,
                    valuePath: "practice.groupNPI",
                },
            },
            {
                header: "CPT Code",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "cptCode",
                },
            },
            {
                header: "Provider Session Cost",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "sessionRate",
                },
                formatting: {
                    currency: true,
                },
            },
            {
                header: `Payer Id (${GENERAL_CONFIG.supportedInsurersUrl})`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "payer.payerId",
                },
            },
            {
                header: "Member Id",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "demographics.memberId",
                },
                alternativeDataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "memberId",
                },
            },
            {
                header: "Member DOB",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "dob",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
            },
            {
                header: "Member First Name (optional)",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "demographics.firstName",
                },
                alternativeDataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "firstName",
                },
            },
            {
                header: "Member Last Name (optional)",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "demographics.lastName",
                },
                alternativeDataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "lastName",
                },
            },
            {
                header: `Network (optional). Options: ${Object.values(
                    PayerCoverageCheckNetwork
                ).join("; ")}`,
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "isInNetworkCheck",
                },
                formatting: {
                    isNetworkTypeValue: true,
                },
            },
            {
                header: `Patient Type (optional). Options: ${Object.values(
                    CoveragePatientType
                ).join("; ")}`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customerPatientType",
                },
            },
            {
                header: `Patient ID (optional)`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customerPatientId",
                },
            },
            {
                header: `Next Appointment Date (optional)`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customerPatientNextAppointmentDate",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
            },

            // RESULTS BELOW
            {
                header: "Time Stamp of the Coverage Check",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "createdAt",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateTimeFormat,
                },
            },
            {
                header: "Response Status",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "responseStatus",
                },
            },
            {
                header: "Request Id",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "requestId",
                },
            },
            {
                header: "Result Type",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "resultType",
                },
            },
            {
                header: "Coverage Status",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "resultType",
                },
                formatting: {
                    isCoverageStatusValue: true,
                },
            },
            {
                header: "Plan Status",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planStatus",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Plan Begin Date",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planBeginDate",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Plan End Date",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planEndDate",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Plan Type",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planType",
                },
                formatting: {
                    planType: true,
                },
            },
            {
                header: "Plan Name",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planName",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Group Name",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "groupName",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Group ID",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "groupId",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Copayment",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "copayment",
                },
                formatting: {
                    currency: true,
                    isCents: true,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Coinsurance",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "coinsurance",
                },
                formatting: {
                    suffixValue: "%",
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Total Deductible",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "deductible",
                },
                formatting: {
                    currency: true,
                    isCents: true,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Remaining Deductible",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "remainingDeductible",
                },
                formatting: {
                    currency: true,
                    isCents: true,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Total Out-of-pocket Max",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "oopMax",
                },
                formatting: {
                    currency: true,
                    isCents: true,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Remaining Out-of-pocket Max",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "remainingOopMax",
                },
                formatting: {
                    currency: true,
                    isCents: true,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Estimated Member Obligation",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "memberObligation",
                },
                formatting: {
                    currency: true,
                    isCents: true,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Estimated Payer Obligation",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "payerObligation",
                },
                formatting: {
                    currency: true,
                    isCents: true,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Estimated Post Deductible Session Rate",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "postDeductibleMemberObligation",
                },
                formatting: {
                    currency: true,
                    isCents: true,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Estimated Post Out-of-pocket Max Session Rate",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "postOopMaxMemberObligation",
                },
                formatting: {
                    currency: true,
                    isCents: true,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Estimated Remaining Sessions Before Deductible",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "remainingSessionsBeforeDeductible",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Estimated Remaining Sessions Before Out-of-pocket Max",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "remainingSessionsBeforeOopMax",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Member Gender",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "demographics.gender",
                },
                formatting: {
                    gender: true,
                },
            },
            {
                header: "Member Address",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "demographics.address",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                    address: true,
                },
            },
            {
                header: "Third Party Payer",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "thirdPartyPayer",
                },
            },

            ...CHECKER_CONFIG.resultsCorrectedDataCsvMapping,

            {
                header: "Error Code",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "coverageError.code",
                },
            },
            {
                header: "Error Message",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "coverageError.message",
                },
            },
        ]
    },
}
