/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { PrimaryText } from "nirvana-react-elements"

import { POLICIES_CONFIG } from "../../../config/policies.config"
import { COVERAGE_CONFIG } from "../../../config/coverage.config"
import { CHECKER_CONFIG } from "../../../config/checker.config"

import editIcon from "../../../assets/images/icons/edit-dark-secondary.svg"

export const SearchFilterDisplayComponent: React.FunctionComponent<
    ISearchFilterDisplayComponentProps
> = props => {
    const getDisplayedAppliedFilters = (): (JSX.Element | null)[] => {
        const appliedFiltersKeys = Object.keys(
            props.existingFilters
        ) as (keyof IPoliciesListFiltersData)[]

        return appliedFiltersKeys.map((key, index) => {
            // Hide has some filters completely from UI
            if (POLICIES_CONFIG.filtersHiddenDisplayKeys.includes(key)) {
                return null
            }

            let filterValue: any = props.existingFilters[key]

            switch (key) {
                case "payer":
                    filterValue =
                        props.existingFilters.payer?.insuranceName || null

                    break

                case "flags":
                    filterValue =
                        filterValue && Array.isArray(filterValue)
                            ? filterValue
                                  .map(
                                      flag =>
                                          POLICIES_CONFIG.flagTypeMappings[flag]
                                              .label
                                  )
                                  .join(", ")
                            : filterValue

                    break

                case "patientType":
                    filterValue = filterValue
                        ? COVERAGE_CONFIG.selectRenderedPatientTypes[
                              filterValue
                          ]?.displayValue
                        : null

                    break

                case "resetBenefitsStatus":
                    filterValue = filterValue
                        ? COVERAGE_CONFIG.selectRenderedResetBenefitStatus[
                              filterValue
                          ]?.displayValue
                        : null

                    break

                case "planType":
                    filterValue = filterValue
                        ? CHECKER_CONFIG.planTypeMapping[filterValue]
                        : null

                    break
            }

            if (!filterValue) {
                return null
            }

            return (
                <div key={index} className="mt-8px flex">
                    <PrimaryText className="mr-12px" typography="textSemibold">
                        {POLICIES_CONFIG.filtersDisplayMapping[key]}:
                    </PrimaryText>

                    <PrimaryText
                        className={`
                            flex-1
                            ${
                                POLICIES_CONFIG.filtersMouseFlowIgnoreFields.includes(
                                    key
                                )
                                    ? "mouseflow-ignore"
                                    : ""
                            }
                        `}
                    >
                        {filterValue}
                    </PrimaryText>
                </div>
            )
        })
    }

    return (
        <div
            className={`
                relative p-16px rounded-12px
                bg-brand-warmLight
                ${props.className}
            `}
        >
            <div
                className="absolute z-10 right-0 top-0 px-12px py-12px cursor-pointer"
                onClick={props.onEditFilters}
            >
                <img src={editIcon} alt="Edit" />
            </div>

            {getDisplayedAppliedFilters()}
        </div>
    )
}
