/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { TooltipItem, ChartData } from "chart.js"
import { SpinnerElement } from "nirvana-react-elements"

import { CHECKER_CONFIG } from "../../../config/checker.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { DoughnutChartElement } from "../../../elements/doughnutChart.element"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"

export const ResultsQuickViewChartComponent: React.FunctionComponent<
    IResultsQuickViewChartComponentProps
> = props => {
    const runtimeState = useAppSelector(runtimeSelector)

    // Hide chart yet if processing checks right now
    const isLoading = useMemo<boolean>(
        () => RuntimeHelper.isBulkCoverageChecksLoading(),
        [runtimeState.isLoading]
    )

    const chartData = useMemo<ChartData<"doughnut", number[], any>>(() => {
        return {
            labels: Object.keys(props.resultsBreakdown).map(
                resultType =>
                    CHECKER_CONFIG.calculatorResultTypeTitleMapping[resultType]
            ),
            datasets: [
                {
                    data: Object.values(props.resultsBreakdown),
                    backgroundColor: Object.keys(props.resultsBreakdown).map(
                        resultType =>
                            CHECKER_CONFIG.calculatorResultTypeColorMapping[
                                resultType
                            ]
                    ),
                    borderWidth: 0,
                },
            ],
        }
    }, [props.resultsBreakdown])

    return !isLoading ? (
        <div
            className={`
                relative
                ${props.className}
            `}
            style={{
                width: `${props.widthPercentage || 100}%`,
            }}
        >
            <DoughnutChartElement
                data={chartData}
                redraw={false}
                hoveredChartLabel={
                    props.hoveredResultType
                        ? CHECKER_CONFIG.calculatorResultTypeTitleMapping[
                              props.hoveredResultType
                          ]
                        : undefined
                }
                tooltipGetLabelCallback={(item: TooltipItem<"doughnut">) => {
                    return `${item.parsed} (${(
                        (item.parsed / props.totalChecks) *
                        100
                    ).toFixed(2)}%)`
                }}
            />
        </div>
    ) : (
        <SpinnerElement containerClassName="mt-48px text-center" />
    )
}
