/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { ReportCoverageCheckResultPopupComponent } from "../../popups/reportCoverageCheckResultPopup.component"
import { PermissionsHelper } from "../../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { CollapseElement } from "../../../elements/collapse.element"
import { CoverageResultBreakdownComponent } from "./coverageResultBreakdown.component"
import { NetworkBadgeElement } from "../../../elements/coverageBadges/networkBadge.element"
import { PartialCoverageBadgeElement } from "../../../elements/coverageBadges/partialCoverageBadge.element"
import { ActiveAtRiskPlanStatusBadgeElement } from "../../../elements/coverageBadges/activeAtRiskPlanStatusBadge.element"
import { LastCheckedBadgeElement } from "elements/coverageBadges/lastCheckedBadge.element"

import flagRedIcon from "../../../assets/images/icons/flag-red.svg"

export const CoverageResultBreakdownWrapperComponent: React.FunctionComponent<
    ICoverageResultBreakdownWrapperComponentProps
> = props => {
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [isReportingResults, setIsReportingResults] = useState<boolean>(false)

    const canReportCoverageCheckResult = useMemo<boolean>(
        () =>
            !props.ignoreReporting &&
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.reportCoverageCheck,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const policyRightHeaderContent = useMemo<JSX.Element>(() => {
        return (
            <div className="flex items-center relative">
                <NetworkBadgeElement coverageResult={props.coverageResult} />

                <PartialCoverageBadgeElement
                    coverageResult={props.coverageResult}
                />

                <ActiveAtRiskPlanStatusBadgeElement
                    coverageResult={props.coverageResult}
                />

                <LastCheckedBadgeElement coverageResult={props.coverageResult} />

                {canReportCoverageCheckResult && (
                    <div
                        className="absolute z-10 right-0 cursor-pointer"
                        onClick={e => {
                            e.stopPropagation()
                            setIsReportingResults(true)
                        }}
                    >
                        <img src={flagRedIcon} alt="Report result" />
                    </div>
                )}
            </div>
        )
    }, [canReportCoverageCheckResult, props.coverageResult])

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <CollapseElement
                className="p-24px"
                label="Policy"
                content={
                    <CoverageResultBreakdownComponent
                        coverageResult={props.coverageResult}
                        requestData={props.requestData}
                        coverageOverride={props.coverageOverride}
                        onSmartScanSuccess={props.onSmartScanSuccess}
                        requestPayer={props.requestPayer}
                        responsePayer={props.responsePayer}
                        requestedHealthProvider={props.requestedHealthProvider}
                        policy={props.policy}
                        isOverridesModeActivated={
                            props.isOverridesModeActivated
                        }
                        overridesFormKey={props.overridesFormKey}
                        isOverridesFormDisabled={props.isOverridesFormDisabled}
                        withFlagsSection={props.withFlagsSection}
                    />
                }
                rightHeaderContent={policyRightHeaderContent}
            />

            {isReportingResults && (
                <ReportCoverageCheckResultPopupComponent
                    isActive
                    coverageResult={props.coverageResult}
                    onDone={() => setIsReportingResults(false)}
                />
            )}
        </div>
    )
}
