import { PrimaryText, ProgressElement } from "nirvana-react-elements"

import { useAppSelector } from "../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"

export const OrganizationQuoteComponent: React.FunctionComponent<{
    className?: string
}> = ({ className }) => {
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    return selectedPracticeRole ? (
        <div
            className={`
                relative px-24px py-16px bg-brand-warmLight rounded-8px
                ${className}
            `}
        >
            <ProgressElement
                containerClassName="mt-16px"
                percent={Math.floor(
                    ((selectedPracticeRole.practice.coveragePortalMonthlyQuota -
                        selectedPracticeRole.monthlyCoverageQuotaLeft) /
                        selectedPracticeRole.practice
                            .coveragePortalMonthlyQuota) *
                        100
                )}
                showInfo={false}
                strokeColor="linear-gradient(90deg, #9073F2 0%, #2C1F45 100%)"
                trailColor="rgba(173, 157, 146, 0.5)"
            />

            <PrimaryText className="mt-8px">
                Your organization has run{" "}
                {new Intl.NumberFormat().format(
                    selectedPracticeRole.practice.coveragePortalMonthlyQuota -
                        selectedPracticeRole.monthlyCoverageQuotaLeft
                )}{" "}
                out of{" "}
                {new Intl.NumberFormat().format(
                    selectedPracticeRole.practice.coveragePortalMonthlyQuota
                )}{" "}
                checks this month.
            </PrimaryText>
        </div>
    ) : null
}
