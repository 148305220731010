/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"

import {
    CoverageCheckerManualCheckType,
    CoverageCheckerRunningState,
} from "../../../config/checker.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { checkerSelector } from "../../../store/selectors/checker.selector"
import { OrganizationQuoteComponent } from "../../general/quotaChecksProgressComponent.component"
import { ManualCheckTypeSelectorComponent } from "./manualCheckTypeSelector.component"
import { ContinuousMonitoringCheckerComponent } from "./continuousMonitoring/continuousMonitoringChecker.component"
import { IntakeCheckerComponent } from "./intake/intakeChecker.component"

export const CoverageCheckerManualComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const checkerState = useAppSelector(checkerSelector)

    const isDataInputRunningState = useMemo<boolean>(() => {
        return (
            checkerState.runningState ===
            CoverageCheckerRunningState.INPUT_MANUAL
        )
    }, [checkerState.runningState])

    const getCheckerBySelectedType = (): JSX.Element => {
        switch (checkerState.manualCheckType) {
            case CoverageCheckerManualCheckType.CONTINUOUS_MONITORING:
                return (
                    <ContinuousMonitoringCheckerComponent className="mt-24px" />
                )

            case CoverageCheckerManualCheckType.INTAKE:
                return <IntakeCheckerComponent className="mt-24px" />
        }
    }

    return (
        <div
            className={`
                ${props.className}
                relative
            `}
        >
            <div>
                {isDataInputRunningState && (
                    <>
                        {/*Encountered when quota is reached*/}
                        <OrganizationQuoteComponent className="mt-44px max-w-800px md:mt-24px" />
                        <ManualCheckTypeSelectorComponent className="mt-24px" />
                    </>
                )}

                {getCheckerBySelectedType()}
            </div>
        </div>
    )
}
