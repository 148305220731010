/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { PrimaryText } from "nirvana-react-elements"

import {
    CHECKER_CONFIG,
    CalculatorResultType,
    CoverageCheckerMode,
} from "../../../config/checker.config"
import { PayerCoverageCheckNetwork } from "../../../config/coverage.config"
import { ResultsQuickViewChartComponent } from "./resultsQuickViewChart.component"
import { CsvHelper } from "../../../helpers/csv.helper"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { checkerSelector } from "../../../store/selectors/checker.selector"

export const ResultsQuickViewComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const checkerState = useAppSelector(checkerSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [hoveredResultType, setHoveredResultType] =
        useState<CalculatorResultType>()

    const resultsStats = useMemo<{
        breakdown: Partial<Record<CalculatorResultType, number>>
        dataCorrections: {
            total: number
            items: Record<string, number>
        }
    }>(() => {
        const breakdown = {}
        const dataCorrections = {
            total: 0,
            items: {},
        }

        checkerState.continuousMonitoringCoverageChecks.forEach(check => {
            for (const network of [
                PayerCoverageCheckNetwork.IN,
                PayerCoverageCheckNetwork.OUT,
            ]) {
                const result: ICoverageResult | undefined =
                    check.result?.[network]

                if (!result?.resultType) {
                    continue
                }

                breakdown[result.resultType] = breakdown[result.resultType] || 0
                breakdown[result.resultType] += 1

                // Calculate data corrections
                CHECKER_CONFIG.resultsCorrectedDataCsvMapping.forEach(
                    correctionMapping => {
                        // Make sure that it doesn't contain additional data
                        const correctionKey = correctionMapping.header
                            .split("(")[0]
                            .trim()

                        // Make sure key exists in result
                        dataCorrections.items[correctionKey] =
                            dataCorrections.items[correctionKey] || 0

                        const columnValue = CsvHelper.getSingleExportColumn(
                            correctionMapping,
                            check.inputData,
                            result,
                            selectedPracticeRole
                        )

                        if (columnValue) {
                            dataCorrections.total++
                            dataCorrections.items[correctionKey]++
                        }
                    }
                )
            }
        })

        return {
            breakdown,
            dataCorrections,
        }
    }, [checkerState.continuousMonitoringCoverageChecks])

    const resultsCount = useMemo<{
        total: number
        successful: number
        failed: number
    }>(() => {
        const total =
            checkerState.continuousMonitoringCoverageChecks?.reduce(
                (accumulator, item) =>
                    accumulator +
                    (item.result?.IN ? 1 : 0) +
                    (item.result?.OUT ? 1 : 0),
                0
            ) || 0

        const successful = CHECKER_CONFIG.quickResultsSuccessfulResults.reduce(
            (accumulator, item) => {
                accumulator += resultsStats.breakdown[item] || 0
                return accumulator
            },
            0
        )

        return {
            total,
            successful,
            failed: total - successful,
        }
    }, [resultsStats.breakdown])

    const getFormattedPercentage = (amount: number, total?: number): string => {
        return ((amount / (total || resultsCount.total)) * 100).toFixed(2)
    }

    const getResultTypeRendering = (
        resultType: string,
        index: number
    ): JSX.Element | null => {
        if (
            checkerState.checkerMode === CoverageCheckerMode.CSV &&
            CHECKER_CONFIG.csvRunQuickViewExcludedResultTypes.includes(
                resultType as CalculatorResultType
            )
        ) {
            return null
        }

        const resultTypeCount = resultsStats.breakdown[resultType]

        return (
            <div
                key={index}
                className="pt-24px flex items-center cursor-help"
                onMouseEnter={() =>
                    setHoveredResultType(resultType as CalculatorResultType)
                }
                onMouseLeave={() => setHoveredResultType(undefined)}
            >
                <div
                    className="mr-8px w-16px h-16px rounded-4px relative top--2px"
                    style={{
                        backgroundColor:
                            CHECKER_CONFIG.calculatorResultTypeColorMapping[
                                resultType
                            ],
                    }}
                />

                <PrimaryText className="flex-1 mr-16px">
                    {
                        CHECKER_CONFIG.calculatorResultTypeTitleMapping[
                            resultType
                        ]
                    }
                </PrimaryText>

                <PrimaryText>
                    {resultTypeCount ? (
                        <>
                            <span className="text-bold">{resultTypeCount}</span>{" "}
                            ({getFormattedPercentage(resultTypeCount)}
                            %)
                        </>
                    ) : (
                        "-"
                    )}
                </PrimaryText>
            </div>
        )
    }

    return resultsCount.total ? (
        <div
            className={`
                relative bg-brand-white
                pl-40px pr-24px py-40px
                ${props.className}
            `}
        >
            <div className="flex md:block">
                <div className="w-400px md:w-full">
                    <PrimaryText
                        size={29}
                        lineHeight={35}
                        weight="bold"
                        centered
                    >
                        Total Checks: {resultsCount.total}
                    </PrimaryText>

                    <ResultsQuickViewChartComponent
                        className="mt-16px mx-auto"
                        totalChecks={resultsCount.total}
                        resultsBreakdown={resultsStats.breakdown}
                        widthPercentage={60}
                        hoveredResultType={hoveredResultType}
                    />
                </div>

                <div className="flex-1 pt-8px md:pt-0px md:mt-24px">
                    <div className="flex md:block">
                        <div className="flex-1 px-24px mr-16px md:mt-24px md:mr-0px">
                            <PrimaryText typography="subtitle">
                                <span className="text-bold">
                                    Successful: {resultsCount.successful}
                                </span>{" "}
                                (
                                {getFormattedPercentage(
                                    resultsCount.successful
                                )}
                                %)
                            </PrimaryText>

                            {Object.keys(resultsStats.breakdown)
                                .filter(item =>
                                    CHECKER_CONFIG.quickResultsSuccessfulResults.includes(
                                        item
                                    )
                                )
                                .map(getResultTypeRendering)}
                        </div>

                        <div className="flex-1 px-24px md:mt-24px">
                            <PrimaryText typography="subtitle">
                                <span className="text-bold">
                                    Unsuccessful: {resultsCount.failed}
                                </span>{" "}
                                ({getFormattedPercentage(resultsCount.failed)}%)
                            </PrimaryText>

                            {Object.keys(resultsStats.breakdown)
                                .filter(
                                    item =>
                                        !CHECKER_CONFIG.quickResultsSuccessfulResults.includes(
                                            item
                                        )
                                )
                                .map(getResultTypeRendering)}
                        </div>
                    </div>

                    {resultsStats.dataCorrections.total ? (
                        <>
                            {/*DIVIDER*/}
                            <div className="flex h-1px mx-24px my-32px bg-brand-grey5 md:hidden" />

                            <div className="mt-24px">
                                <PrimaryText
                                    typography="subtitle"
                                    className="px-24px"
                                >
                                    <span className="text-bold">
                                        Data Corrections:{" "}
                                        {resultsStats.dataCorrections.total}
                                    </span>
                                </PrimaryText>

                                <div className="flex flex-wrap md:block">
                                    {Object.keys(
                                        resultsStats.dataCorrections.items
                                    ).map((key, index) => {
                                        const correctionCount =
                                            resultsStats.dataCorrections.items[
                                                key
                                            ]

                                        return (
                                            <div
                                                key={index}
                                                className="flex-basis-50 flex items-center pt-24px px-24px"
                                            >
                                                <PrimaryText className="flex-1 mr-16px">
                                                    {key}
                                                </PrimaryText>

                                                <PrimaryText>
                                                    {correctionCount ? (
                                                        <>
                                                            <span className="text-bold">
                                                                {
                                                                    correctionCount
                                                                }
                                                            </span>{" "}
                                                            (
                                                            {getFormattedPercentage(
                                                                correctionCount,
                                                                resultsStats
                                                                    .dataCorrections
                                                                    .total
                                                            )}
                                                            %)
                                                        </>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </PrimaryText>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    ) : null
}
