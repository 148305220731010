import React from "react"
import moment, { Moment } from "moment-timezone"
import { isObject } from "lodash-es"
import {
    PolicyModality,
    PrimaryText,
    TooltipElement,
} from "nirvana-react-elements"

import {
    CHECKER_CONFIG,
    CalculatorResultType,
    ExportDataSource,
} from "../config/checker.config"
import {
    AvailableModalityCoverageStatus,
    AvailablePlanStatus,
    COVERAGE_CONFIG,
    PayerCoverageCheckNetwork,
} from "../config/coverage.config"
import {
    CoveragePortalFlagType,
    POLICIES_CONFIG,
    PolicyDenialRisk,
    ResetBenefitStatus,
} from "../config/policies.config"
import { POLICIES_COLUMNS_CONFIG } from "../config/policiesColumns.config"
import { UtilHelper } from "./util.helper"
import { PoliciesHelper } from "./policies.helper"
import { GENERAL_CONFIG } from "../config/general.config"
import { PlanYearResetBreakdownComponent } from "../components/policies/planYearResets/planYearResetBreakdown.component"

import benefitsNotDetectedIcon from "../assets/images/icons/reset-benefits-not-detected.svg"
import benefitsNotRequiredIcon from "../assets/images/icons/reset-benefits-action-not-required.svg"
import benefitsRequiredIcon from "../assets/images/icons/reset-benefits-action-required.svg"

export class CsvHelper {
    /**
     * Get single column for download CSV based on mapping
     */
    static getSingleExportColumn(
        mapping: ICsvFieldsMapping,
        inputData: IContinuousMonitoringCoverageCheckInputData,
        resultData?: ICoverageResult,
        selectedPracticeRole?: IPracticeRole
    ): any {
        const emptyColumnValue = mapping.formatting?.convertOnEmptyToUnknown
            ? COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown
            : undefined

        let columnValue = CsvHelper.getSingleExportColumnValue(
            mapping.dataSource,
            inputData,
            resultData,
            selectedPracticeRole,
            emptyColumnValue
        )

        if (columnValue === emptyColumnValue && mapping.alternativeDataSource) {
            columnValue = CsvHelper.getSingleExportColumnValue(
                mapping.alternativeDataSource,
                inputData,
                resultData,
                selectedPracticeRole,
                emptyColumnValue
            )
        }

        // Process special formatting for columns, like money or date formatting
        // Also check if it has some value
        if (mapping.formatting && columnValue !== emptyColumnValue) {
            columnValue = CsvHelper.getFormattedColumnValue(
                columnValue,
                mapping.formatting,
                emptyColumnValue,
                false,
                resultData
            )
        }

        // Always convert boolean to 1 | 0
        if (typeof columnValue === "boolean") {
            columnValue = +columnValue
        }

        // If we need to compare with resulted value, find resulted data and compare - add to csv only if differs
        if (mapping.dataCompareWith) {
            const compareValue = CsvHelper.getSingleExportColumn(
                {
                    header: mapping.header,

                    ...mapping.dataCompareWith,
                },
                inputData,
                resultData,
                selectedPracticeRole
            )

            if (
                !columnValue ||
                columnValue?.toLowerCase() === compareValue?.toLowerCase()
            ) {
                return emptyColumnValue
            } else {
                return columnValue
            }
        }

        return columnValue
    }

    /**
     * Get formatting of some value based on formatting config
     */
    static getFormattedColumnValue(
        columnValue: any,
        formatting: ICsvFieldFormatting,
        emptyColumnValue: any,
        jsxSupportedFormatting = false,
        coverageResultData?: ICoverageResult | null
    ): any {
        try {
            if (formatting.currency) {
                let amount = parseFloat(columnValue.toString())

                // Convert to cents if it's not already in cents
                if (!formatting.isCents) {
                    amount = UtilHelper.formatDollarsToCents(amount)
                }

                if (isNaN(amount)) {
                    columnValue = emptyColumnValue
                } else {
                    // Convert to $
                    columnValue = UtilHelper.getFormattedMoney(
                        amount,
                        formatting.showZeroFractionDigits
                    )
                }
            }

            if (formatting.dateFormat) {
                columnValue = moment(columnValue).format(formatting.dateFormat)
            }

            if (formatting.suffixValue) {
                columnValue = `${columnValue}${formatting.suffixValue}`
            }

            if (formatting.isCoverageStatusValue) {
                columnValue =
                    columnValue === CalculatorResultType.noCoverage
                        ? AvailableModalityCoverageStatus.inactive
                        : CHECKER_CONFIG.activeCoverageResults.includes(
                              columnValue
                          )
                        ? AvailableModalityCoverageStatus.active
                        : AvailableModalityCoverageStatus.unknown
            }

            if (formatting.resultType) {
                columnValue = columnValue
                    ? CHECKER_CONFIG.activeCoverageResults.includes(columnValue)
                        ? CHECKER_CONFIG.calculatorResultTypeTitleMapping
                              .activeCoverage
                        : CHECKER_CONFIG.calculatorResultTypeTitleMapping[
                              columnValue
                          ] || null
                    : null
            }

            if (formatting.isNetworkTypeValue) {
                columnValue = columnValue
                    ? PayerCoverageCheckNetwork.IN
                    : PayerCoverageCheckNetwork.OUT
            }

            if (formatting.isInNetworkCheckMapped) {
                columnValue = columnValue
                    ? PayerCoverageCheckNetwork.IN
                    : PayerCoverageCheckNetwork.OUT

                columnValue =
                    COVERAGE_CONFIG.payerCoverageCheckNetworkMapped[columnValue]
                        ?.displayValue || columnValue
            }

            if (formatting.address) {
                columnValue =
                    UtilHelper.getFormattedDemographicsAddress(columnValue)
            }

            if (formatting.gender) {
                columnValue = CHECKER_CONFIG.genderMapping[columnValue]
            }

            if (formatting.planType) {
                columnValue = CHECKER_CONFIG.planTypeMapping[columnValue]
            }

            if (formatting.planStatus) {
                columnValue = CHECKER_CONFIG.activePlanStatuses.includes(
                    columnValue
                )
                    ? AvailablePlanStatus.active
                    : columnValue
            }

            if (formatting.modalityCoverageStatus) {
                columnValue =
                    COVERAGE_CONFIG.availableModalityCoverageStatusMapping[
                        columnValue
                    ] ||
                    COVERAGE_CONFIG.availableModalityCoverageStatusMapping[
                        AvailableModalityCoverageStatus.unknown
                    ]
            }

            if (formatting.priorAuthorization) {
                columnValue =
                    COVERAGE_CONFIG.availablePriorAuthorizationMapping[
                        columnValue
                    ] || emptyColumnValue
            }

            if (formatting.flagsList) {
                let icon =
                    POLICIES_CONFIG.policyDenialRiskIconsMapping[
                        PolicyDenialRisk.NONE
                    ]

                let text = "No Alerts"

                if (Array.isArray(columnValue) && columnValue.length) {
                    const mappings = (
                        columnValue as CoveragePortalFlagType[]
                    ).map(item => POLICIES_CONFIG.flagTypeMappings[item])

                    text = mappings.map(item => item.label).join("\n")

                    const denialRisk = PoliciesHelper.getPolicyDenialRisk(
                        columnValue as CoveragePortalFlagType[]
                    )

                    icon =
                        POLICIES_CONFIG.policyDenialRiskIconsMapping[denialRisk]
                }

                columnValue = jsxSupportedFormatting ? (
                    <div className="flex items-start">
                        <div className="relative top--2px mr-8px">
                            <img src={icon} alt="flag icon" />
                        </div>

                        <PrimaryText>
                            {text.split("\n").map((str, index) => (
                                <div key={index}>{str}</div>
                            ))}
                        </PrimaryText>
                    </div>
                ) : (
                    text.split("\n").join(", ")
                )
            }

            if (formatting.denialRisk) {
                if (!Array.isArray(columnValue) || !columnValue.length) {
                    columnValue =
                        POLICIES_CONFIG.denialRiskMapping[PolicyDenialRisk.NONE]
                } else {
                    const denialRisk = PoliciesHelper.getPolicyDenialRisk(
                        columnValue as CoveragePortalFlagType[]
                    )

                    columnValue = POLICIES_CONFIG.denialRiskMapping[denialRisk]
                }
            }

            if (columnValue && formatting.isPatientTypeMapped) {
                columnValue =
                    COVERAGE_CONFIG.selectRenderedPatientTypes[columnValue]
                        ?.displayValue
            }

            if (columnValue && formatting.resetBenefitStatus) {
                const mappedValue =
                    COVERAGE_CONFIG.selectRenderedResetBenefitStatus[
                        columnValue
                    ]?.displayValue

                if (!jsxSupportedFormatting) {
                    columnValue = mappedValue
                } else {
                    let icon = benefitsNotDetectedIcon

                    switch (columnValue) {
                        case ResetBenefitStatus.DETECTED_NO_ACTION_REQUIRED:
                            icon = benefitsNotRequiredIcon

                            break

                        case ResetBenefitStatus.DETECTED_ACTION_REQUIRED:
                            icon = benefitsRequiredIcon

                            break
                    }

                    columnValue = (
                        <div className="flex items-center">
                            <div className="relative top--2px mr-8px">
                                <img src={icon} alt="icon" />
                            </div>

                            <PrimaryText>{mappedValue}</PrimaryText>
                        </div>
                    )
                }
            }

            if (formatting.resetBenefitDifference) {
                columnValue = isObject(columnValue) ? columnValue : {}

                const differenceKeys: string[] = []

                if (coverageResultData) {
                    Object.keys(columnValue).forEach(key => {
                        let resetBenefitValue = columnValue[key]
                        let coverageResultValue = coverageResultData[key]

                        // Make it null if empty for any reason
                        resetBenefitValue =
                            typeof resetBenefitValue === "undefined"
                                ? null
                                : resetBenefitValue

                        // Make it null if empty for any reason
                        coverageResultValue =
                            typeof coverageResultValue === "undefined"
                                ? null
                                : coverageResultValue

                        if (resetBenefitValue !== coverageResultValue) {
                            differenceKeys.push(key)
                        }
                    })
                }

                const mappedDifferences = differenceKeys.map(
                    key =>
                        Object.values(
                            POLICIES_COLUMNS_CONFIG.columnsConfig
                        ).find(
                            column =>
                                column.sourcePropertyPath === key ||
                                column.alternativeSourcePropertyPath === key
                        )?.label || key
                )

                columnValue = mappedDifferences.join(", ") || emptyColumnValue

                if (jsxSupportedFormatting) {
                    columnValue = (
                        <div className="flex items-center">
                            <PrimaryText>{columnValue}</PrimaryText>

                            {coverageResultData ? (
                                <TooltipElement
                                    className="ml-8px relative top--2px flex-shrink-0"
                                    text={
                                        <PlanYearResetBreakdownComponent
                                            className="shadow-7 top--24px"
                                            coverageCheckHistory={
                                                {
                                                    // safe to cast to full coverage check, since it's checking only result values inside
                                                    coverageResult:
                                                        coverageResultData,
                                                } as ICoverageCheckHistory
                                            }
                                        />
                                    }
                                />
                            ) : null}
                        </div>
                    )
                }
            }
        } catch (e) {}

        return columnValue
    }

    /**
     * Get coverage portal download columns
     */
    static getCoverageCheckerDownloadCSVColumns(
        neededModalities = [PolicyModality.MENTAL_HEALTH]
    ): ICsvFieldsMapping[] {
        const neededColumns = [...CHECKER_CONFIG.resultsCsvMapping]

        const additionalColumns = [
            ...Object.values(POLICIES_COLUMNS_CONFIG.columnsConfig),

            ...PoliciesHelper.getModalitiesSpecificColumnsConfigurations(
                neededModalities
            ),
        ].filter(item => item.addCheckerCSVDownload)

        // Process additional columns that require specific order
        for (const column of additionalColumns) {
            if (typeof column.coverageCheckerCsvDownloadOrder === "undefined") {
                continue
            }

            neededColumns.splice(
                column.coverageCheckerCsvDownloadOrder,
                0,
                ...PoliciesHelper.convertPolicyColumnsToCsvFieldsMappings([
                    column,
                ])
            )
        }

        const additionalColumnsWithoutOrder =
            PoliciesHelper.convertPolicyColumnsToCsvFieldsMappings(
                additionalColumns.filter(
                    item =>
                        typeof item.coverageCheckerCsvDownloadOrder ===
                        "undefined"
                )
            )

        // Will insert new columns without order before data corrections columns
        const index = neededColumns.findIndex(
            item =>
                item.header ===
                CHECKER_CONFIG.resultsCorrectedDataCsvMapping[0].header
        )

        // Just append to end
        if (index < 0) {
            return [...neededColumns, ...additionalColumnsWithoutOrder]
        }

        neededColumns.splice(index, 0, ...additionalColumnsWithoutOrder)

        return neededColumns
    }

    /**
     * Download a CSV document
     */
    static createAndDownloadCsvDocument(data: string, fileName = "download") {
        let url: string = ""

        try {
            const blob = new Blob([data], {
                type: "text/csv",
            })

            url = URL.createObjectURL(blob)

            const downloadLink = document.createElement("a")

            downloadLink.href = url
            downloadLink.download = `${fileName}-${moment().format(
                GENERAL_CONFIG.defaultMomentDateTimeFormat
            )}.csv`

            downloadLink.click()
        } finally {
            URL.revokeObjectURL(url)
        }
    }

    /**
     * Check different formats of date string
     */
    static getPossibleDateField(
        value?: string,
        failOnAllInvalid = false
    ): {
        primaryFormat?: Moment
        secondaryFormat?: Moment
        thirdFormat?: Moment
    } {
        const primaryFormat = value
            ? moment(value, GENERAL_CONFIG.defaultMomentDateFormat, true)
            : undefined

        const secondaryFormat = value
            ? moment(value, GENERAL_CONFIG.mysqlMomentDateFormat, true)
            : undefined

        const thirdFormat = value
            ? moment(
                  value,
                  GENERAL_CONFIG.defaultMomentDateFormatShortYear,
                  true
              )
            : undefined

        if (
            failOnAllInvalid &&
            !primaryFormat?.isValid() &&
            !secondaryFormat?.isValid() &&
            !thirdFormat?.isValid()
        ) {
            throw new Error("All date formats are invalid")
        }

        return {
            primaryFormat,
            secondaryFormat,
            thirdFormat,
        }
    }

    /**
     * Get single column for download CSV based on config
     */
    private static getSingleExportColumnValue(
        fieldConfiguration: ICsvDataFieldConfig,
        inputData: IContinuousMonitoringCoverageCheckInputData,
        resultData?: ICoverageResult,
        selectedPracticeRole?: IPracticeRole,
        emptyColumnValue?: string
    ): any {
        let columnValue: any

        switch (fieldConfiguration.source) {
            case ExportDataSource.inputData:
                columnValue = inputData

                break

            case ExportDataSource.outputData:
                columnValue = resultData

                break

            case ExportDataSource.practiceRole:
                columnValue = selectedPracticeRole

                break
        }

        if (!columnValue) {
            return emptyColumnValue
        }

        // Go through each value path and assign it's value to column value, break down by "." so we can support nested properties of objects
        fieldConfiguration.valuePath.split(".").forEach(pathPart => {
            try {
                columnValue = columnValue?.[pathPart]
            } catch (e) {
                columnValue = emptyColumnValue
            }
        })

        // IF resulting value is undefined or null -> assign empty string to it
        if (typeof columnValue === "undefined" || columnValue === null) {
            columnValue = emptyColumnValue
        }

        return columnValue
    }
}
